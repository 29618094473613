@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import "../fonts/fonts";
@import "reset.scss";
@import "variables.scss";
@import "layout";
@import "mixins";
@import "badges.scss";
@import "login.scss";
@import "forgot-password.scss";
@import "sidebar.scss";
@import "charts.scss";
@import "datepicker.scss";
@import "topbar.scss";
@import "panel.scss";
@import "forms.scss";
@import "common.scss";
@import "feedback.scss";
@import "insights.scss";
@import "customers.scss";
@import "users.scss";
@import "animations.scss";
@import "dashboard.scss";
@import "user-profile";
@import "new-manage-user";
@import "secondary-topbar";
@import "footer";
@import "organization_setup";
@import "add-survey.scss";
@import "organization-division";
@import "pivotable";
@import "questionnaire";
@import "setup-profile";

::selection {
  background-color: var(--secondary-color-light);
  // color: var(--secondary-color-light);
}

::-moz-selection {
  background-color: var(--secondary-color-light);
  // color: var(--secondary-color-light);
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  background: #f5f5f5;
  color: #707070;
  overscroll-behavior-y: none;
  overflow-x: hidden;
  line-height: 1.42857143;
  font-size: 14px;
}

#content-wrapper {
  padding: 0 30px;
  background: #f5f5f5;
  // overflow: hidden;
  // overflow-x: hidden;
  position: relative;
  min-height: calc(100vh - 135px);

  > span {
    display: block;
  }
}

footer {
  text-align: center;
  background: #f4f4f2;
  padding: 5px;

  // position: absolute;
  // left: 0;
  // bottom: 0;
  width: 100%;

  a {
    color: darken($color: #f4f4f2, $amount: 40);
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
    color: darken($color: #f4f4f2, $amount: 60);
  }
}

//Custom Tooltip
.tooltipRel {
  position: relative;

  &:hover {
    .tooltip-text-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }
}

// Custom Scroll
.custom-scrollbar {
  .custom-scroll-handle {
    right: 0;
  }

  .inner-handle {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
}

// Spinner
.sk-spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  opacity: 0.9;
  transform: translate(-50%, -50%);

  > div {
    background-color: $red;
  }

  div {
    width: 12px;
    height: 12px;
    animation: sk-cubemove 2s infinite ease-in-out;
  }

  div:first-child {
    background-color: $color2;
  }
}

// 404
.notFound {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-data-found {
  font-weight: bolder;
  color: currentColor;
  font-size: 19px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-family: "SegoeUI-Light", Arial, Helvetica, sans-serif;
}

.organization-not-found {
  background: white;
  border: 1px solid #666;
  border-radius: 50%;
  box-shadow: 5px 30px 30px 30;
  font-size: 25px;
  text-align: center;
  width: 700px;
  position: absolute;
  left: calc(50% - 350px);
  top: calc(50% - 350px);
  padding: 350px 0px;
  box-shadow: 0px 0px 0px 250px $color1, 0px 0px 0px 500px lighten($color1, 4%),
    0px 0px 0px 750px lighten($color1, 8%),
    0px 0px 0px 1000px lighten($color1, 12%);

  & span {
    display: block;
    font-size: 13px;
    color: $color10;
  }
}

// Misc
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px lighten($brand-color-secondary, 40%) inset !important;
}

.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.tooltip {
  .tooltip-inner {
    text-align: left;
    max-width: 300px;
  }
}

.help {
  position: fixed;
  background: $brand-color-primary;
  z-index: 999;
  bottom: 55px;
  right: -22px;
  width: 70px;
  text-align: center;
  border: 1px solid white;
  transform: rotate(-90deg);

  a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 4px 10px;
    color: white;
    text-decoration: none;
    outline: none !important;
  }
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: $color14;
}

.btn-primary {
  border-color: transparent;
  background: var(--primary-color);
  color: var(--text-color);

  &:hover,
  &:active,
  &:focus,
  &:visited {
    background: var(--primary-color);
    color: var(--text-color);
  }

  &.dropdown-toggle {
    background: transparent;

    &:after {
      display: none;
      content: "";
    }
  }
}

.priority-list td {
  padding: 7px !important;
}

.parent-option {
  font-size: (12, initial);
  font-weight: bold;
  color: rgb(160, 160, 160);
}

.priority-value {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

  & span {
    font-size: 14px;
    color: #777;
    font-weight: normal;
  }

  &.benchmarks {
    text-align: left;
  }
}

#priority-list-tabs {
  height: inherit;

  .tab-content,
  #priority-list-tabs-pane-overall,
  .error-message,
  #priority-list-tabs-pane-inner,
  #priority-list-tabs-pane-outer {
    height: inherit;
  }
}

.thumbnail {
  max-height: 100px;
  max-width: 100px;
}

.info-icon {
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
}

.plotlyjsicon {
  display: none;
}

.notification {
  cursor: pointer;
  color: $filter-color;

  &.disabled {
    cursor: not-allowed;
    color: $color7;
  }
}

.notification-all {
  color: $light-gray;
  background: $filter-color;
}

.alert-msg {
  margin: 5px 0 !important;
}

.highlight-text {
  background-color: yellow;
}

// including scrollbar
@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

// no scroll bar
@mixin no-scroll-bar() {
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    height: 0;
    width: 0;
  }
}

.input-label {
  margin: 0 5px 0 0;
}

.compare-toggle {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  border-radius: 2px;
}

.react-toggle--checked .react-toggle-track {
  background: $color3;
}

.react-toggle-thumb {
  background: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  background-color: #ccc;
}

.clear-col-padding {
  padding-left: 0;
  padding-right: 0;
}

.clear-col-padding-right {
  padding-right: 0;
}

.clear-col-padding-left {
  padding-right: 0;
}

.ranges {
  overflow-y: scroll;
  max-height: 290px;
}

.timeseries .tab-content,
.timeseries canvas {
  height: 268px !important;
  display: flex;
}

.timeseries .tab-content .tab-pane {
  width: 100%;
}

.timeseries .nav.nav-tabs {
  margin-top: -20px;
}

.timeseries .nav-item.nav-link {
  border: none;
  border-bottom: solid 2px transparent;
  margin-right: 5px;
}

.timeseries .nav-item.nav-link.active {
  border-bottom: solid 2px var(--primary-color);
}

.timeseries .nav-item.nav-link.active {
  border-bottom-color: var(--primary-color);
}

.timeseries .nav-item.nav-link:hover {
  border-bottom-color: var(--primary-color);
}

.timeseries .tab-content {
  margin-top: 10px;
}

.primary-button {
  padding: 10px;
  color: white;
  background: $brand-color-primary;
  text-align: center;
  cursor: pointer;
  float: right;

  @media #{$MaxPhone} {
    float: left;
  }
}

.list {
  list-style: none;
  padding: 0;

  & li:hover {
    background: lightgray;
  }
}

.tooltip-table {
  background: black;
  color: white;
}

.dropdown-wrapper {
  position: relative;
}

.css-2b097c-container {
  min-height: 46px;
  .css-yk16xz-control {
    min-height: 46px;
    border-color: #e9e9e9;
    .css-g1d714-ValueContainer {
      min-height: 46px;
      padding: 2px 24px;
      .css-1rhbuit-multiValue {
        padding: 2px 8px;
        border-radius: 14px;
        background-color: #e9e9e9;
      }
    }
    .css-g1d714-ValueContainer:invalid {
      border: 1px solid #f33;
    }
    .css-1hb7zxy-IndicatorsContainer {
      display: none !important;
    }
  }
  .css-1pahdxg-control {
    min-height: 46px;
  }
}

.select__control--is-focused {
  padding: 0 16px;
  .select__multi-value {
    padding: 2px 8px;
    border-radius: 14px;
    background-color: #e9e9e9;
  }
}

.select__menu {
  z-index: 10 !important;
}

.css-1gtu0rj-indicatorContainer {
  display: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.dateRangePicker {
  .rdrDateRangeWrapper {
    width: 100%;
  }

  .rdrDateDisplay {
    &Wrapper {
      background-color: transparent;
      display: none;
    }

    &ItemActive {
      border-color: var(--primary-color);
    }
  }

  .rdrNextPrevButton {
    background-color: #eee;

    &:hover {
      background-color: #ccc;
    }

    &.rdrPprevButton i {
      border-color: transparent var(--primary-color) transparent transparent;
    }

    &.rdrNextButton i {
      border-color: transparent transparent transparent var(--primary-color);
    }
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: var(--primary-color);
    top: 3px;
    left: 0;
    right: 0;
    bottom: 3px;
  }

  .rdrStartEdge {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .rdrEndEdge {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .rdrDay,
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    color: var(--primary-color) !important;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: var(--primary-color);
    bottom: 0;
    width: 10px;
  }

  .rdrMonth {
    width: 17rem;
  }
}

.css-1rhbuit-multiValue {
  padding: 2px 8px;
  border-radius: 14px !important;
  background-color: #e9e9e9 !important;
}

.css-1q5dc95-control {
  &:hover,
  &:focus {
    border: 0 !important;
  }
}

.react-horizontal-scrolling-menu--scroll-container {
  display: -webkit-inline-box !important;
  overflow-x: auto;
  width: 100%;
  height: auto;
}
