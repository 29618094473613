.parent-row {
  display: flex;

  .is-active {
    margin-left: 30px;
  }
}

.division-container {
  margin-top: 30px;

  .add-division-btn {
    margin-bottom: 20px;
  }
}

.Collapsible {
  padding-left: 50px;
  padding-right: 50px;
  border: solid 1px lightgray;
  width: 90%;
}
.children-row:last-child {
  padding-bottom: 20px;
}
.leaf {
  padding-left: 50px;
  border: solid 1px lightgray;
  height: 40px;
  padding-top: 10px;
  width: 90%;
}

.leaf-container {
  display: flex;
}

.edit-division {
  font-size: 15px;
  margin-left: 30px;
  cursor: pointer;
}

.add-edit-division-form-container {
  background-color: #ffffff;
  margin: 30px auto;
  padding: 50px;
  width: 60%;
  border-radius: 5px;
}

.division-row {
  width: 100%;
  > div {
    display: inline-block;
  }
}

.Collapsible {
  background-color: white;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  padding: 10px;
}
.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}
