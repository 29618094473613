.footer {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;

  a {
    font-size: 14px;
  }

  .poweredBy {
    text-align: end;
    color: black;
    display: none;

    @media #{$min-tablet} {
      display: block;
    }
  }
  .sm-logo {
    display: block;
    margin: 0 auto;
    // opacity: 0.5;
  }
  .col-sm-6 {
    padding-left: 0px;
  }
}
