.setup-profile {
  min-height: calc(100vh - 140px);
  padding: 0 100px;

  @media #{$MaxPhone} {
    padding: 0;
  }

  .logo {
    max-height: 60px;
    margin: 15px 0;
    @media #{$tablet} {
      display: block;
      margin: 15px auto;
      max-height: 50px;
    }
  }

  .panel-body {
    padding: 15px 15px 50px;
    h3 {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  @media #{$min-tablet} {
    .fields-wrapper {
      display: flex;
    }
  }

  .error {
    color: $red;
    font-weight: bold;

    a {
      color: $red;

      &:focus,
      &:active,
      &:hover,
      &:visited {
        color: $red;
      }
    }

    .not-allowed {
      margin-right: 5px;
      font-size: 17px;
    }
  }

  .has-error .form-control {
    color: $red;
    border-color: $red;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-control {
    height: 40px;
  }

  button {
    width: 100%;
    background: var(--primary-color);
    float: none;
  }

  .image-field {
    // opacity: 0;
    // width: 0.1px;
    // height: 0.1px;
    // z-index: -1;

    // &::before {
    //     content: 'Select an image';
    //     display: inline-block;
    //     background: linear-gradient(top, #f9f9f9, #e3e3e3);
    //     border: 1px solid #999;
    //     border-radius: 3px;
    //     padding: 5px 8px;
    //     outline: none;
    //     white-space: nowrap;
    //     -webkit-user-select: none;
    //     cursor: pointer;
    //     text-shadow: 1px 1px #fff;
    //     font-weight: 700;
    //     font-size: 10pt;
    // }

    // &:hover::before {
    //     border-color: black;
    // }

    // &:active::before {
    //     background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    // }
    display: none;
  }
  .button-holder {
    text-align: center;
    .image-select-label {
      background: var(--primary-color);
      color: var(--text-color);
    }
    .fa {
      position: static;
      margin: 0 5px 0 0;
    }
  }
  .avatar-image {
    display: block;
    width: 150px;
    height: 150px;
    margin: 20px auto 10px;
    border: 2px solid #eee;
    border-radius: 50%;
  }
}
