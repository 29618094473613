.daterangepicker {
  &.opensright:before {
    right: 40px;
    left: unset;
  }

  &.opensright:after {
    right: 41px;
    left: unset;
  }
  td.in-range {
    background-color: #f1f1f1;
  }
  td.active,
  td.active:hover {
    background-color: var(--primary-color);
  }
}
