.survey-wizard-container {
  width: 70%;
  margin: 20px auto;

  .add-remove-question-container {
    margin-top: 15px;
    .remove-question {
      width: 150px;
    }

    .add-question {
      width: 150px;
    }
  }
}
.add-survey-detail-container {
  background-color: #ffffff;
  padding: 25px 10%;
  border-radius: 5px;
  .form-group {
    margin-bottom: 15px;
  }

  textarea {
    resize: none;
    height: 70px;
  }

  .survey-configs-container {
    margin-top: 30px;
  }
}

.question-form-container {
  position: relative;
  background-color: #ffffff;
  padding: 25px 10%;
  border-radius: 5px;
  margin-top: 30px;

  .option-form-container {
    @extend .question-form-container;
    padding: 25px 3%;
    border: solid 1px #d3d3d3;
    box-shadow: 3px 3px 10px grey;
    margin-bottom: 10px;

    .sub-option-form-container {
      @extend .option-form-container;
      padding: 25px 3%;
      border: solid 1px #d3d3d3;
      box-shadow: 3px 3px 10px grey;
      margin-bottom: 10px;
    }
  }

  .maximum-selected-options {
    input {
      width: 100%;
      padding: 5px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
      text-align: center;
      margin-bottom: 5px;
    }

    .label-text {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
}

.delete-container {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.container-heading {
  position: relative;
  bottom: 20px;
  right: 40px;
  color: gray;
}

.opt-container-heading {
  position: relative;
  bottom: 20px;
  color: gray;
}

.add-survey-btn {
  position: fixed;
  top: 90px;
  right: 2%;

  .sidebar-btn {
    width: 150px;
  }
}

.survey-list-tbl {
  margin-top: 25px;

  td:last-child {
    button {
      width: 100px;
    }
  }
}

.add-new-survey {
  margin-top: 20px;
}

.toggle-active-status {
  position: absolute;
  top: 13px;
  right: 55px;
}

.delete-btn {
  display: block;
  width: max-content;
  height: max-content;
  position: relative;
  padding: 10px;
  border: 1px solid #555;
  margin-bottom: 15px;

  &:hover {
    border: 1px solid black;
  }

  i.fa.fa-times {
    top: -7px;
    right: -7px;
    left: unset;
    background: white;
    border-radius: 50px;
    color: #555;
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  & i + * {
    margin: 0;
  }
}
