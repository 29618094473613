:root {
  --primary-color: #005aab;
  --secondary-color: #ffe400;
  --darker: grey;
  --text-color: white;
}

// Brand
$brand-color-primary: rgb(0, 90, 171);
$brand-color-secondary: #ffe400;

//common
$dark-blue: #1c1a24;
$darkest-blue: #16151c;
$sea-green: #64ddd7;
$red: #d50d1e;
$light-gray: #f4f4f2;
$light-gray-1: #eaeaea;
$light-gray-2: #c6c8ca;
$green: #33a95c;
$body-text: #1c1a24;
$blue-green: #32b0ae;

$white: #fff;
$black: #000;
$darkgrey: #c7c6c6;
$lightgrey: lightgrey;
$grey: #808080;
$orange: #ffa037;
$blue: #4d92fb;
$green: #69bc6c;

// Topbar
$topbar-height: 70px;

// Sidebar
$sidebar-width: 250px;
$sidebar-width-sm: 80px;
$sidebar-width-xs: auto;

// Color Palette
$color1: rgba(255, 64, 64, 1);
$color2: rgba(255, 160, 55, 1);
$color3: rgba(75, 174, 79, 1);
$color4: rgba(95, 124, 138, 1);
$color5: rgba(232, 29, 98, 1);
$color6: rgb(52, 73, 179);
$color7: rgba(157, 157, 157, 1);
$color8: rgba(120, 84, 71, 1);
$color9: rgba(32, 149, 242, 1);
$color10: rgba(54, 63, 59, 1);
$color11: rgba(0, 149, 135, 1);
$color12: rgba(254, 151, 0, 1);
$color13: rgba(125, 89, 131, 1);
$color14: rgba(160, 160, 160, 1);

$filter-color: rgb(0, 90, 171);

// Breakpoints
$phone: "(min-width: 768px)";
$MaxPhone: "(max-width: 769px)";
$min-tablet: "(min-width: 992px)";
$small-screen: "1400px";
$tablet: "(max-width: 992px)";
$desktop: "(max-width: 1500px)";

// Fonts Sizes
$heading-size: 15px;
$subtext-size: 12px;
