@mixin with-count($n) {
  @if $n == 1 {
    &:first-child:nth-last-child(1) {
      @content;
    }
  } @else {
    &:first-child:nth-last-child(#{$n}),
    &:first-child:nth-last-child(#{$n}) ~ & {
      @content;
    }
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

$maxFont: 50;

@mixin font-sm-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1280px) {
      .fontsize-sm-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@mixin font-md-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1366px) {
      .fontsize-md-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@mixin font-lg-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1400px) {
      .fontsize-lg-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@mixin font-xl-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1200px) {
      .fontsize-xl-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@mixin font-xxl-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1400px) {
      .fontsize-xxl-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@mixin font-xxxl-list {
  @for $i from 1 through $maxFont {
    @media screen and (min-width: 1900px) {
      .fontsize-xxxl-#{$i} {
        font-size: $i * 1px !important;
      }
    }
  }
}

@include font-sm-list;
@include font-md-list;
@include font-lg-list;
@include font-xl-list;
@include font-xxl-list;
@include font-xxxl-list;
