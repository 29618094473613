#login {
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: #c4c4c4;
  background: url("../img/background.png") no-repeat center;
  .login-holder {
    position: absolute;
    max-width: 450px;
    min-width: 400px;
    // width: 70%;
    padding: 30px;
    box-sizing: border-box;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    .logo {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 90%;
      }
    }
    input:not([disabled]):not([type="submit"]) {
      background-color: white;
      box-shadow: none;
      border: 0;
      border-radius: 0;
      padding: 10px 0 15px 56px;
      color: black;
      height: 70px;
      font-size: 16px;
      position: relative;
    }
    .form-group {
      margin-bottom: 15px;
      position: relative;
      .fa {
        font-size: 16px;
        position: absolute;
        top: calc(50% - 12px);
        left: 15px;
        z-index: 1;
      }
    }
    button {
      border-radius: 0;
      border: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding: 12px;
      height: 70px;
      &:focus {
        outline: 0;
      }
      &:hover {
        background-color: darken($brand-color-primary, 12%);
      }
    }
  }

  .pt-md-1 {
    padding-top: 15px;
  }
}
