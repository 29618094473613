#sidebar {
  width: $sidebar-width-xs;
  border-right: 1px solid #ccc;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media #{$phone} {
    width: $sidebar-width-sm;
    position: fixed;
  }

  @media #{$min-tablet} {
    width: $sidebar-width;
  }

  #logo-holder {
    @media #{$phone} {
      background-size: 70%;
      height: 110px;
    }

    @media #{$min-tablet} {
      background-size: 75%;
    }
  }

  #nav {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      width: 25%;

      @media #{$phone} {
        display: block;
        width: 100%;
      }

      a {
        color: whitesmoke;
        display: block;
        font-weight: 300;
        text-decoration: none;
        border-left: none;
        border-bottom: 3px solid transparent;
        font-size: 0;
        line-height: normal;
        text-align: center;
        padding: 15px 0;

        @media #{$phone} {
          font-size: 0;
          padding: 20px 0;
          border-left: 3px solid transparent;
          border-bottom: none;
        }

        @media #{$min-tablet} {
          line-height: 60px;
          font-size: 16px;
          text-align: left;
          padding: 0 0 0 40px;
        }

        &:hover,
        &.active {
          border-left: none;
          border-bottom: 3px solid whitesmoke;

          @media #{$phone} {
            border-bottom: none;
            border-left: 3px solid whitesmoke;
          }
        }

        .fa {
          margin-right: 10px;
          font-size: 20px;
          min-width: 16px;
          text-align: center;

          @media #{$phone} {
            display: inline-block;
          }

          @media #{$min-tablet} {
            font-size: 16px;
          }
        }
      }
    }
  }

  .poweredBy {
    text-align: center;
    color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    position: absolute;
    bottom: 15px;
    display: none;

    @media #{$min-tablet} {
      display: block;
    }

    img {
      width: 40%;
      display: block;
      margin: 0 auto;
      opacity: 0.5;
    }
  }
}
