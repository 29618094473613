.secondary-topbar {
  margin-top: 15px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .user {
    display: block;

    @media #{$phone} {
      display: inline-block;
    }

    .download-icon {
      line-height: 47px;
      border-left: none;
      padding: 12px !important;
      cursor: pointer;
      .download-icon-img {
        height: 34px;
      }
    }
    .downloading-file {
      line-height: 47px;
      cursor: not-allowed;
      .download-icon-img {
        height: 34px;
        margin-right: 9px;
      }
      .fa {
        color: black;
      }
    }

    position: relative;

    .dropdown {
      .btn-default {
        background: transparent;
      }
    }

    &:last-child {
      padding-right: 0;
    }
    .dropdown-toggle {
      padding: 0;
      border: 0;
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        background: transparent;
      }
    }
    .dropdown-menu {
      right: 0;
      left: auto;
      > li {
        > a {
          padding: 15px;
          i {
            min-width: 15px;
          }
        }
        &.divider {
          margin: 0;
        }
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    li.applied-filters {
      background: dodgerblue;
      color: white;
      padding: 3px 10px;
      font-weight: bold;
      margin: 5px 5px 0 0;
      display: inline-block;
    }
  }
  & .options {
    margin-top: 5px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      min-height: 3em;
      min-width: 3em;
      text-align: center;
      border-radius: 0;
      cursor: pointer;
      background-color: var(--filters-color);
      color: #fff;

      img,
      svg {
        width: 20px;
        height: 20px;
        object-fit: contain;
        object-position: center;
      }

      .fa {
        font-size: 1.3em;
        border-radius: 2px;
        line-height: 2.2em;
      }

      &.flash {
        box-shadow: 0px 0px 6px 3px var(--secondary-color);
        animation: flash 1s linear 0s 10;
      }
    }
    .option-group {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .option {
        margin: 0;
        position: relative;
      }
      > div {
        position: relative;
      }

      div:not(:first-child) .option::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 55%;
        top: 0;
        margin: auto;
        left: -1px;
        bottom: 0;
        border-left: 1px solid #fff;
      }
    }
    .selected-range {
      line-height: 2em;
      cursor: pointer;
      min-width: 3em;
      margin: 0 0 0 5px;
      display: flex;
      justify-content: space-evenly;

      i {
        display: none;
      }

      > div {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }

      span {
        white-space: nowrap;
        position: relative;
        width: auto;
        display: block;
        padding: 0 1em;
        flex: 1;
      }

      span:not(:first-child)::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 70%;
        top: 0;
        margin: auto;
        left: -1px;
        bottom: 0;
        border-left: 1px solid #fff;
      }
    }
  }

  .filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      margin: 0 8px;
      border-radius: 0;

      svg {
        color: blue;
      }
      svg rect {
        color: currentColor;
      }
    }
  }
}

div#nps-customer-options-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 0;
  .popover-content {
    padding: 0;
    border-radius: 0;
    > div {
      padding-bottom: 22px;
      padding-top: 10px;
      border-bottom: 1px solid lightgray;
      div.heading {
        display: flex;
        justify-content: space-between;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  li {
    color: #585858;
    list-style: none;
    cursor: pointer;
    .option-entry {
      width: 180px;
      padding: 2px 15px;
    }
  }
  div.heading {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
  }
}

div#survey-types-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 5px;
  .popover-content {
    padding: 0;
    border-radius: 0;

    > div:last-child > li > div.option-entry {
      border-bottom: none;
    }

    .no-survey-types {
      padding: 10px;
    }
  }
  .reset-filter {
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
    float: right;
  }
  li {
    color: #585858;
    list-style: none;
    cursor: pointer;
    .option-entry {
      width: auto;
      min-width: 200px;
      padding: 10px;
      border-bottom: 1px solid lightgray;
      .selected-icon {
        float: right;
      }
    }
  }

  div.heading {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
  }
}

.customer-type-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 0;
  .popover-content {
    padding: 0;
    border-radius: 0;
    > div {
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid lightgray;
    }
  }
  li {
    color: #585858;
    list-style: none;
    cursor: pointer;
    .option-entry {
      width: 180px;
      padding: 2px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.alert-message {
  text-align: left !important;

  a {
    text-decoration: none;
    display: table;
  }

  .icon {
    background: var(--primary-color);
    color: white;
    display: table-cell;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    text-align: center;
    padding: 11px 0;
  }

  .text {
    color: var(--primary-color);
    background: white;
    padding: 11px;
    border-left: 1px solid white;
    display: inline-block;
    max-width: 370px;

    &:hover {
      background: var(--primary-color);
      color: white;
    }
  }
}
