.customers,
.questionaire {
  padding-top: 30px;

  .box {
    background: white;
    box-shadow: 5px 5px 0 #e8e8e4;
    padding: 20px;
    min-height: 290px;
  }

  thead {
    background: inherit;
    font-size: $heading-size;
    color: #51cbce;
  }

  th,
  td {
    height: 50px;
    text-align: center;
  }

  table thead th,
  table tbody td {
    border: 0;
  }

  table.table-bordered {
    border: 0;
  }

  .table > tbody + tbody {
    border-top: 0;
  }

  table tbody td {
    border-top: 1px solid #dee2e6 !important;
  }

  .action-wrapper {
    position: relative;

    .actions {
      position: absolute;
      z-index: 1;
      visibility: hidden;
      width: 70px;
      right: 0;
    }

    &:hover {
      .actions {
        visibility: visible;
      }
    }
  }

  h4.name {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .fa-sort {
    cursor: pointer;
    padding-left: 5px;
    font-size: 18px;
  }

  span.subtext {
    display: block;
    color: $color14;
    font-size: $subtext-size;
  }

  .contact {
    font-size: $subtext-size;
    display: block;
  }

  .email {
    overflow-wrap: anywhere;
  }

  .link {
    font-size: $subtext-size;
    overflow-wrap: anywhere;
  }

  .sort {
    display: inline-block;
    padding: 5px;
  }

  i.arrow {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;

    &.up {
      transform: rotate(-135deg);
    }

    &.down {
      margin: -8px;
      transform: rotate(45deg);
    }
  }

  &.questionnaire-stats {
    margin-top: 50px;

    .table {
      margin-bottom: 25px;
    }
  }
}

.pagination {
  display: flex;
  float: center;
  justify-content: center;
  color: #337ab7;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  li {
    display: inline;
    margin-left: -1px;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 6px 0px;

    &.active,
    &:hover:not(.disabled) {
      z-index: 3;
      color: #fff;
      cursor: pointer;
      background-color: #337ab7;
      border-color: #337ab7;
    }
  }

  li > a {
    padding: 6px 12px;
  }

  li.disabled {
    cursor: not-allowed;
    a {
      cursor: not-allowed;
      color: #777;
    }
  }
}
