.insights {
  padding-top: 30px;
  min-height: calc(100% - 160px);
  @extend .charts;
  .panel {
    margin-bottom: 30px;

    .line-chart {
      .panel-heading {
        padding: 0 !important;
      }
    }

    @at-root.insight-line-chart-container {
      display: block;
      width: 100%;

      .title {
        margin-right: 15px;
      }

      .line-header {
        .line-header-nps,
        .line-header-complaints {
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
          font-weight: bold;

          span {
            float: unset;
          }
          * {
            margin-right: 20px;
          }
        }

        .line-header-nps {
          float: left;
        }

        .line-header-complaints {
          float: right;
        }
      }
    }

    @at-root.line-chart-body {
      min-height: 275px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex: 1;
    }

    .panel-body {
      padding: 20px 15px;
      min-height: 275px;
    }
    &.table {
      margin-bottom: 0;
      .panel-body {
        height: calc(40vh - 25px);
        //min-height: 0;
      }
    }
  }
  .customer-stats {
    .panel {
      height: calc(100% - 60px);

      .panel-body {
        text-align: center;
        font-size: 36px;
        color: #333;
        height: auto;
        min-height: 80px;
      }
    }
  }
  .table {
    margin-bottom: 0;
    tr {
      td,
      th {
        word-break: break-all;
        width: 35%;
        &:last-child {
          width: 30%;
        }
      }
    }
  }
  .custom-scroll {
    padding-bottom: 20px;
  }
  .filter-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    .filter {
      .filter-label {
        text-align: left;
        font-size: 11px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        .selected-filter {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          .breadcrumb-parent:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .dropdown-button {
          .fa {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .toggle-option {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    color: #003e7d;
    font-weight: bold;
    .toggle-button {
      display: flex;
      justify-content: flex-end;
    }
  }

  .comment-entry {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    .comment-rating {
      background-color: #2d9e2d;
      padding: 5px 15px;
      color: whitesmoke;
      font-size: 23px;
      font-weight: bold;
    }
    .comment-text {
      line-height: 1.5em;
      height: 3em;
      overflow: hidden;
      margin: 10px;
      margin-bottom: 0px;
    }
    .comment-date-time {
      margin-left: 10px;
      color: #737373;
      * {
        margin-right: 20px;
      }
    }
  }
  .comments-container {
    .panel-body {
      min-height: 580px;
      height: 60vh;
    }
  }
}

div#filter-options-list {
  margin-left: 5px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.09);
  padding: 0;
  .popover-content {
    padding: 0;
    border-radius: 0;
  }
  li {
    color: #020202;
    list-style: none;
    cursor: pointer;
    .option-entry {
      width: 180px;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      border-bottom: 1px solid #c2c2c4;
    }
  }
}
