.corner-badge {
  border: 1px solid $color5;

  &:before {
    content: "*";
    padding: 5px 5px 0 5px;
    color: white;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    display: flex;
    width: 70px;
    height: 70px;
    position: absolute;
    margin: -35px -35px;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
    background: $color5;
  }
}

.current-badge {
  @extend .corner-badge;

  &:before {
    content: "current";
  }
}
