.horizontal-scroll-item {
  padding-right: 20px;
  width: 92%;
}

.horizontal-scroll-item-full-screen {
  width: 100%;
}

.horizontal-scroll-wrapper {
  width: 100%;
  overflow: scroll;
  height: 100%;
}
