@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI/SegoeUI.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUI.woff") format("woff"), url("../fonts/SegoeUI/SegoeUI.ttf") format("truetype"), url("../fonts/SegoeUI/SegoeUI.svg#SegoeUI") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI-Light";
  src: url("../fonts/SegoeUI/SegoeUI-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUI-Light.woff") format("woff"), url("../fonts/SegoeUI/SegoeUI-Light.ttf") format("truetype"), url("../fonts/SegoeUI/SegoeUI-Light.svg#SegoeUI-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI-Medium";
  src: url("../fonts/SegoeUI/SegoeUI-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUI-Medium.woff") format("woff"), url("../fonts/SegoeUI/SegoeUI-Medium.ttf") format("truetype"), url("../fonts/SegoeUI/SegoeUI-Medium.svg#SegoeUI-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SegoeUI-Bold";
  src: url("../fonts/SegoeUI/SegoeUI-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SegoeUI/SegoeUI-Bold.woff") format("woff"), url("../fonts/SegoeUI/SegoeUI-Bold.ttf") format("truetype"), url("../fonts/SegoeUI/SegoeUI-Bold.svg#SegoeUI-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}
a {
  text-decoration: none;
  color: var(--primary-color);
}
a:hover, a:visited, a:active, a:focus {
  text-decoration: none;
}

.btn,
.input,
.html {
  font-size: 14px;
  line-height: 1.42857143;
}

.btn-group-vertical > .btn-group::after,
.btn-group-vertical > .btn-group::before,
.btn-toolbar::after,
.btn-toolbar::before,
.clearfix::after,
.clearfix::before,
.container-fluid::after,
.container-fluid::before,
.container::after,
.container::before,
.dl-horizontal dd::after,
.dl-horizontal dd::before,
.form-horizontal .form-group::after,
.form-horizontal .form-group::before,
.modal-footer::after,
.modal-footer::before,
.modal-header::after,
.modal-header::before,
.nav::after,
.nav::before,
.navbar-collapse::after,
.navbar-collapse::before,
.navbar-header::after,
.navbar-header::before,
.navbar::after,
.navbar::before,
.pager::after,
.pager::before,
.panel-body::after,
.panel-body::before {
  display: table;
  content: " ";
}

.btn-group-vertical > .btn-group::after,
.btn-toolbar::after,
.clearfix::after,
.container-fluid::after,
.container::after,
.dl-horizontal dd::after,
.form-horizontal .form-group::after,
.modal-footer::after,
.modal-header::after,
.nav::after,
.navbar-collapse::after,
.navbar-header::after,
.navbar::after,
.pager::after,
.panel-body::after,
.row::after {
  clear: both;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.panel {
  margin-bottom: 20px;
}
.panel .panel-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.panel .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel .panel-heading.info {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

.form-control {
  font-size: 14px;
}

:root {
  --primary-color: #005aab;
  --secondary-color: #ffe400;
  --darker: grey;
  --text-color: white;
}

.row {
  margin-right: -8px;
  margin-left: -8px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 8px;
  padding-right: 8px;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

.sticky-portal {
  position: sticky;
  top: 70px;
  background: #f4f4f2;
  z-index: 1;
}

@media screen and (min-width: 1280px) {
  .fontsize-sm-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1280px) {
  .fontsize-sm-50 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1366px) {
  .fontsize-md-50 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-lg-50 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1200px) {
  .fontsize-xl-50 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1400px) {
  .fontsize-xxl-50 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-1 {
    font-size: 1px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-2 {
    font-size: 2px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-3 {
    font-size: 3px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-4 {
    font-size: 4px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-5 {
    font-size: 5px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-6 {
    font-size: 6px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-7 {
    font-size: 7px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-8 {
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-9 {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-10 {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-11 {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-12 {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-13 {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-14 {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-15 {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-16 {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-17 {
    font-size: 17px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-18 {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-19 {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-20 {
    font-size: 20px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-21 {
    font-size: 21px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-22 {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-23 {
    font-size: 23px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-24 {
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-25 {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-26 {
    font-size: 26px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-27 {
    font-size: 27px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-28 {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-29 {
    font-size: 29px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-30 {
    font-size: 30px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-31 {
    font-size: 31px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-32 {
    font-size: 32px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-33 {
    font-size: 33px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-34 {
    font-size: 34px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-35 {
    font-size: 35px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-36 {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-37 {
    font-size: 37px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-38 {
    font-size: 38px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-39 {
    font-size: 39px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-40 {
    font-size: 40px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-41 {
    font-size: 41px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-42 {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-43 {
    font-size: 43px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-44 {
    font-size: 44px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-45 {
    font-size: 45px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-46 {
    font-size: 46px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-47 {
    font-size: 47px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-48 {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-49 {
    font-size: 49px !important;
  }
}
@media screen and (min-width: 1900px) {
  .fontsize-xxxl-50 {
    font-size: 50px !important;
  }
}
.corner-badge, .current-badge {
  border: 1px solid rgb(232, 29, 98);
}
.corner-badge:before, .current-badge:before {
  content: "*";
  padding: 5px 5px 0 5px;
  color: white;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  display: flex;
  width: 70px;
  height: 70px;
  position: absolute;
  margin: -35px -35px;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
  background: rgb(232, 29, 98);
}

.current-badge:before {
  content: "current";
}

#login {
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: #c4c4c4;
  background: url("../img/background.png") no-repeat center;
}
#login .login-holder {
  position: absolute;
  max-width: 450px;
  min-width: 400px;
  padding: 30px;
  box-sizing: border-box;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
#login .login-holder .logo {
  text-align: center;
  margin-bottom: 20px;
}
#login .login-holder .logo img {
  width: 90%;
}
#login .login-holder input:not([disabled]):not([type=submit]) {
  background-color: white;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  padding: 10px 0 15px 56px;
  color: black;
  height: 70px;
  font-size: 16px;
  position: relative;
}
#login .login-holder .form-group {
  margin-bottom: 15px;
  position: relative;
}
#login .login-holder .form-group .fa {
  font-size: 16px;
  position: absolute;
  top: calc(50% - 12px);
  left: 15px;
  z-index: 1;
}
#login .login-holder button {
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px;
  height: 70px;
}
#login .login-holder button:focus {
  outline: 0;
}
#login .login-holder button:hover {
  background-color: rgb(0, 57.7894736842, 109.8);
}
#login .pt-md-1 {
  padding-top: 15px;
}

#forgot-password {
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: #c4c4c4;
  background: url("../img/background.png") no-repeat center;
}
#forgot-password .forgot-password-holder {
  position: absolute;
  max-width: 450px;
  padding: 30px;
  box-sizing: border-box;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
#forgot-password .forgot-password-holder .logo {
  text-align: center;
  margin-bottom: 20px;
}
#forgot-password .forgot-password-holder .logo img {
  width: 90%;
}

#sidebar {
  width: auto;
  border-right: 1px solid #ccc;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  #sidebar {
    width: 80px;
    position: fixed;
  }
}
@media (min-width: 992px) {
  #sidebar {
    width: 250px;
  }
}
@media (min-width: 768px) {
  #sidebar #logo-holder {
    background-size: 70%;
    height: 110px;
  }
}
@media (min-width: 992px) {
  #sidebar #logo-holder {
    background-size: 75%;
  }
}
#sidebar #nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
#sidebar #nav li {
  display: inline-block;
  width: 25%;
}
@media (min-width: 768px) {
  #sidebar #nav li {
    display: block;
    width: 100%;
  }
}
#sidebar #nav li a {
  color: whitesmoke;
  display: block;
  font-weight: 300;
  text-decoration: none;
  border-left: none;
  border-bottom: 3px solid transparent;
  font-size: 0;
  line-height: normal;
  text-align: center;
  padding: 15px 0;
}
@media (min-width: 768px) {
  #sidebar #nav li a {
    font-size: 0;
    padding: 20px 0;
    border-left: 3px solid transparent;
    border-bottom: none;
  }
}
@media (min-width: 992px) {
  #sidebar #nav li a {
    line-height: 60px;
    font-size: 16px;
    text-align: left;
    padding: 0 0 0 40px;
  }
}
#sidebar #nav li a:hover, #sidebar #nav li a.active {
  border-left: none;
  border-bottom: 3px solid whitesmoke;
}
@media (min-width: 768px) {
  #sidebar #nav li a:hover, #sidebar #nav li a.active {
    border-bottom: none;
    border-left: 3px solid whitesmoke;
  }
}
#sidebar #nav li a .fa {
  margin-right: 10px;
  font-size: 20px;
  min-width: 16px;
  text-align: center;
}
@media (min-width: 768px) {
  #sidebar #nav li a .fa {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  #sidebar #nav li a .fa {
    font-size: 16px;
  }
}
#sidebar .poweredBy {
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  position: absolute;
  bottom: 15px;
  display: none;
}
@media (min-width: 992px) {
  #sidebar .poweredBy {
    display: block;
  }
}
#sidebar .poweredBy img {
  width: 40%;
  display: block;
  margin: 0 auto;
  opacity: 0.5;
}

.charts, .insights,
.questionaire {
  margin-top: 20px;
}
.charts .panel, .insights .panel,
.questionaire .panel {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 16px;
  padding: 20px 10px;
  height: calc(100% - 16px);
}
.charts .panel .panel-heading, .insights .panel .panel-heading,
.questionaire .panel .panel-heading {
  border-radius: 0;
  color: #707070;
  background-color: white;
  border: none;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 0;
  padding: 0 0 15px;
  text-transform: capitalize;
  text-align: center;
  min-height: 40px;
}
.charts .panel .panel-title, .insights .panel .panel-title,
.questionaire .panel .panel-title {
  font-size: 15px;
}
.charts .panel .panel-title span, .insights .panel .panel-title span,
.questionaire .panel .panel-title span {
  float: right;
}
.charts .panel .panel-body, .insights .panel .panel-body,
.questionaire .panel .panel-body {
  min-height: 310px;
  height: 15vw;
  padding: 0;
  border-left: none;
}
.charts .panel .panel-body div.row, .insights .panel .panel-body div.row,
.questionaire .panel .panel-body div.row {
  height: 99%;
}
.charts .panel .avg-rating, .insights .panel .avg-rating,
.questionaire .panel .avg-rating {
  position: absolute;
  top: 20px;
  right: 55px;
}
.charts .panel .avg-rating .star-icon, .insights .panel .avg-rating .star-icon,
.questionaire .panel .avg-rating .star-icon {
  color: yellow;
}
.charts .panel .avg-rating .avg-rating-score, .insights .panel .avg-rating .avg-rating-score,
.questionaire .panel .avg-rating .avg-rating-score {
  background-color: lightgrey;
  padding: 3px 10px 3px 10px;
  font-size: 18px;
  font-weight: bold;
}
.charts #counter, .insights #counter,
.questionaire #counter {
  text-align: center;
  position: absolute;
  left: 0;
  transform: translate(0, -50%);
  top: 50%;
  right: 0;
}
.charts #counter text, .insights #counter text,
.questionaire #counter text {
  color: #333;
  font-weight: bold;
  text-align: center;
  font-size: 100px;
  display: block;
  line-height: normal;
  padding: 2% 0;
  letter-spacing: -6px;
}
.charts #counter h5, .insights #counter h5,
.questionaire #counter h5 {
  color: rgb(125.125, 125.125, 105.875);
  margin-bottom: 0;
  font-weight: normal;
  font-size: 16px;
  margin-top: 0;
  line-height: 22px;
}
.charts #counter h5 span, .insights #counter h5 span,
.questionaire #counter h5 span {
  margin-right: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.charts #counter h5 span:before, .insights #counter h5 span:before,
.questionaire #counter h5 span:before {
  margin-right: 4px;
  font-family: FontAwesome, sans-serif;
}
.charts #counter h5 span[class*=down], .insights #counter h5 span[class*=down],
.questionaire #counter h5 span[class*=down] {
  color: rgb(255, 64, 64);
}
.charts #counter h5 span[class*=up], .insights #counter h5 span[class*=up],
.questionaire #counter h5 span[class*=up] {
  color: rgb(75, 174, 79);
}
.charts #counter h5.red strong, .insights #counter h5.red strong,
.questionaire #counter h5.red strong {
  color: rgb(255, 64, 64);
}
.charts #counter h5.green strong, .insights #counter h5.green strong,
.questionaire #counter h5.green strong {
  color: rgb(75, 174, 79);
}
.charts #counter h5 strong, .insights #counter h5 strong,
.questionaire #counter h5 strong {
  margin-left: 5px;
}
.charts .custom-labels, .insights .custom-labels,
.questionaire .custom-labels {
  margin: 0;
  padding: 0 0 10px 0;
  list-style: none;
  text-align: center;
}
.charts .custom-labels li, .insights .custom-labels li,
.questionaire .custom-labels li {
  display: inline-block;
  margin-right: 8px;
  padding-left: 24px;
  position: relative;
  font-size: 12px;
  color: rgb(97.5, 97.5, 82.5);
}
.charts .custom-labels li:last-child, .insights .custom-labels li:last-child,
.questionaire .custom-labels li:last-child {
  margin-right: 0;
}
.charts .custom-labels li:before, .insights .custom-labels li:before,
.questionaire .custom-labels li:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #f4f4f2;
}
.charts .custom-labels li:nth-child(1):before, .insights .custom-labels li:nth-child(1):before,
.questionaire .custom-labels li:nth-child(1):before {
  background-color: rgb(255, 64, 64);
}
.charts .custom-labels li:nth-child(2):before, .insights .custom-labels li:nth-child(2):before,
.questionaire .custom-labels li:nth-child(2):before {
  background-color: rgb(255, 160, 55);
}
.charts .custom-labels li:nth-child(3):before, .insights .custom-labels li:nth-child(3):before,
.questionaire .custom-labels li:nth-child(3):before {
  background-color: rgb(75, 174, 79);
}
.charts .feedback-stats-card, .insights .feedback-stats-card,
.questionaire .feedback-stats-card {
  margin-bottom: 0;
  min-height: 1px !important;
  max-height: none !important;
}
.charts .feedback-stats-card .panel-body, .insights .feedback-stats-card .panel-body,
.questionaire .feedback-stats-card .panel-body {
  height: 5vw !important;
}
.charts .feedback-stats-card .panel-heading, .insights .feedback-stats-card .panel-heading,
.questionaire .feedback-stats-card .panel-heading {
  padding: 0;
}

#chartjs-tooltip {
  padding: 15px;
  background: black;
  border-radius: 5px;
  opacity: 0.9;
}

table.chart-tooltip {
  background: black;
  color: white;
  opacity: 0.9;
}
table.chart-tooltip thead {
  border-bottom: 2px solid white;
  text-align: center;
}
table.chart-tooltip td, table.chart-tooltip th {
  padding: 3px;
}

.questionaire .panel .panel-heading {
  height: 48px;
  padding: 0;
}

div[id*=custom-tooltip] {
  padding: 5px;
  border-radius: 5px;
  opacity: 0.8;
}
div[id*=custom-tooltip] .tooltip-table {
  color: whitesmoke;
}
div[id*=custom-tooltip] .tooltip-table .header {
  text-align: left;
  border-bottom: 2px solid whitesmoke;
}
div[id*=custom-tooltip] .tooltip-table th {
  border-bottom: 2px solid whitesmoke;
}
div[id*=custom-tooltip] .tooltip-table td,
div[id*=custom-tooltip] .tooltip-table th {
  padding: 3px 10px;
  text-align: center;
}
div[id*=custom-tooltip] .tooltip-table tr th:first-child,
div[id*=custom-tooltip] .tooltip-table tr td:first-child {
  text-align: left;
}

#kpi-detail-tooltip {
  font-size: 12px;
}
#kpi-detail-tooltip .table {
  color: whitesmoke;
}

.daterangepicker.opensright:before {
  right: 40px;
  left: unset;
}
.daterangepicker.opensright:after {
  right: 41px;
  left: unset;
}
.daterangepicker td.in-range {
  background-color: #f1f1f1;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--primary-color);
}

#header {
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
}
#header:hover {
  transform: translate3d(0, 0, 0) !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  -o-transform: translate3d(0, 0, 0) !important;
}
#header.hide {
  display: block !important;
}
#header.responsive-header {
  position: sticky;
  background: var(--primary-color);
  width: 100%;
  display: none;
  margin-bottom: 15px;
}
#header.responsive-header.visible {
  display: block;
}
#header.responsive-header .logo-container {
  display: inline-block;
  line-height: 4.5;
}
#header.responsive-header .logo-wrapper {
  width: 170px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 992px) {
  #header.responsive-header .logo-wrapper.setup-profile-panel-logo {
    position: static;
    margin-left: 90px;
    transform: unset;
  }
}
#header.responsive-header .scroll-btn {
  display: inline-block;
  color: white;
  font-size: 20px;
  cursor: pointer;
  line-height: 3.5;
}
#header.responsive-header .scroll-btn.right {
  float: right;
  padding-right: 10px;
}
#header.responsive-header .scroll-btn.left {
  float: left;
  padding-left: 10px;
}
@media (max-width: 992px) {
  #header.responsive-header {
    display: block;
  }
  #header.hide {
    display: none !important;
  }
}
#header .logo-container {
  height: 70px;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 70px;
  cursor: pointer;
}
#header #logo {
  margin: 2% 0;
  height: 70%;
}
#header .store-view-dropdown {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#header .store-view-dropdown .custom_dropdown {
  border-radius: 25px;
}
#header .store-view-dropdown .custom_dropdown-wrapper {
  margin: 0 !important;
}
#header .store-view-dropdown .custom_dropdown-menu {
  border: none;
  padding: 0;
  left: 0;
  right: auto;
}
#header .store-view-dropdown .custom_dropdown-menu ::before {
  right: 20px;
  left: unset;
}
#header .store-view-dropdown .custom_dropdown-menu-list {
  padding: 0;
  max-height: 550px;
  overflow: auto;
  min-width: max-content;
}
#header .store-view-dropdown .custom_dropdown-text {
  color: var(--primary-color);
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.1em;
  height: 1em;
  max-width: 18ch;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  position: relative;
}
#header .store-view-dropdown .custom_dropdown-text::after {
  position: absolute;
  content: "";
  right: 0;
  width: 30px;
  height: 100%;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to right, transparent, white);
}
#header .store-view-dropdown .custom_dropdown-text span {
  color: #b4b5b5;
  font-size: inherit;
  font-family: inherit;
  max-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#header .store-view-dropdown .custom_dropdown-item {
  color: #4d565c;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 0.9em;
  position: relative;
  padding: 10px 13px 10px 30px;
  width: 100%;
  word-break: keep-all;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#header .store-view-dropdown .custom_dropdown-item span {
  color: #b4b5b5;
  font-size: inherit;
  font-family: inherit;
  float: unset !important;
  text-align: right;
}
#header .store-view-dropdown .custom_dropdown-item::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #b4b5b5;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  right: auto;
  margin: auto;
}
#header .store-view-dropdown .custom_dropdown-item.active::before {
  background-color: #89c120;
}
#header .store-view-dropdown .custom_dropdown-item.deactive::before {
  background-color: #e61f1f;
}
#header .store-view-dropdown .custom_dropdown-item.disable::before {
  background-color: #b4b5b5;
}
#header .store-view-dropdown .custom_dropdown-item.dormont::before {
  background-color: #ffa037;
}
#header .store-view-dropdown .custom_dropdown-item.all::before {
  background-color: transparent;
}
#header .row div.navigation-panel {
  min-height: 50px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: -webkit-fill-available;
}
#header .row div.navigation-panel div.navigation-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
}
#header .row div.navigation-panel div.navigation-options .navigation-option {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px 0;
  font-size: 1.1em;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  color: inherit;
  margin-left: 3em;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: lighter;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#header .row div.navigation-panel div.navigation-options .navigation-option .fa {
  margin-right: 5px;
  display: none;
}
#header .row div.navigation-panel div.navigation-options .navigation-option.active::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 7px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: currentColor;
}
#header .row div.navigation-panel div.navigation-options .notification-button {
  margin-left: auto;
  font-size: 1.2rem;
  border: none;
  margin-right: 0;
  padding: 0;
}
#header .row div.navigation-panel div.navigation-options .notification-button.wrapper__button {
  color: #edbe23;
}
#header .row div.navigation-panel div.navigation-options .notification-button.wrapper__button .counter {
  height: 20px;
  width: 20px;
  background-color: #d50d1e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: absolute;
  top: 0px;
  bottom: 10px;
  margin: auto;
  right: -12px;
  border-radius: 50%;
  padding: 3px;
  text-align: center;
}
#header .row div.navigation-panel div.navigation-options .notification-button i {
  font-size: 20px;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications.menu {
  position: absolute;
  top: calc(100% + 4px);
  left: auto;
  box-shadow: 0 0 15px 5px rgba(34, 36, 38, 0.15);
  width: 400px;
  background-color: #fff;
  z-index: 99;
  margin: 0 auto;
  cursor: auto;
  cursor: initial;
  right: -20px;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications.menu::before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 15px solid #fff;
  border-top: 15px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  left: auto;
  right: 12px;
  top: -25px;
  z-index: -1;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications--button {
  align-content: end;
  background-color: #fff;
  box-shadow: none;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  margin: 0;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications--button .see-all {
  border-radius: 0;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications--list {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  color: #707070;
}
#header .row div.navigation-panel div.navigation-options .notification-button .notifications--list .holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-color: #efefef;
  border-bottom-width: 1px;
  padding: 12px 8px;
}
#header .row div.navigation-panel div.navigation-options .user {
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
  margin-left: 0;
}
#header .row div.navigation-panel div.navigation-options .user .download-icon {
  line-height: 47px;
  border-left: none;
  padding: 12px !important;
  cursor: pointer;
}
#header .row div.navigation-panel div.navigation-options .user .download-icon .download-icon-img {
  height: 34px;
}
#header .row div.navigation-panel div.navigation-options .user .downloading-file {
  line-height: 47px;
  cursor: not-allowed;
}
#header .row div.navigation-panel div.navigation-options .user .downloading-file .download-icon-img {
  height: 34px;
  margin-right: 9px;
}
#header .row div.navigation-panel div.navigation-options .user .downloading-file .fa {
  color: black;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown .btn-default {
  background: transparent;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-header {
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-size: 1.1em;
  padding: 1rem 1.5rem;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-header .user-name span {
  color: #707070;
  font-size: 0.75em;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a {
  margin: 0;
}
#header .row div.navigation-panel div.navigation-options .user:last-child {
  padding-right: 0;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-toggle {
  border-radius: 50%;
  background-color: var(--primary-color);
  padding: 0;
  border: 0;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-toggle:hover, #header .row div.navigation-panel div.navigation-options .user .dropdown-toggle:focus, #header .row div.navigation-panel div.navigation-options .user .dropdown-toggle:active {
  box-shadow: none;
  background: transparent;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu {
  top: 100% !important;
  left: auto !important;
  margin: 10px 0 0 !important;
  right: 0 !important;
  bottom: auto !important;
  transform: translate3d(0, 0, 0) !important;
  padding: 10px 20px;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
  text-align: center;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu::before {
  position: absolute;
  top: -9px;
  right: 12px;
  display: inline-block;
  border-right: 9px solid transparent;
  border-bottom: 9px solid white;
  border-left: 9px solid transparent;
  content: "";
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a {
  font-size: 0.9em;
  text-transform: none;
  font-weight: normal;
  padding: 15px;
  color: #4d565c !important;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a i {
  min-width: 15px;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a:hover,
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a:visited,
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a:active,
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu a:focus {
  background-color: #f5f5f5;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu.divider {
  margin: 0;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-menu .dropdown-header {
  white-space: normal;
}
#header .row div.navigation-panel div.navigation-options .user .dropdown-divider {
  margin: 0;
}
#header .row div.navigation-panel div.navigation-options .user-img {
  padding: 0;
}
#header .row div.navigation-panel div.navigation-options .organization {
  display: inline;
  padding-left: 30px;
}
#header .row div.navigation-panel div.navigation-options .organization .organization-dropdown {
  color: white;
  font-size: 1.1em;
  font-weight: lighter;
}
#header .row div.navigation-panel div.navigation-options .organization .organization-dropdown .icon {
  padding-right: 5px;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-toggle {
  padding: 0;
  border: 0;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-toggle:hover, #header .row div.navigation-panel div.navigation-options .organization .dropdown-toggle:focus, #header .row div.navigation-panel div.navigation-options .organization .dropdown-toggle:active {
  box-shadow: none;
  background: transparent;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-menu {
  right: 0;
  left: auto;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-menu a {
  font-size: 1em;
  text-transform: none;
  font-weight: normal;
  padding: 15px;
  color: black !important;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-menu a i {
  min-width: 15px;
}
#header .row div.navigation-panel div.navigation-options .organization .dropdown-menu.divider {
  margin: 0;
}

.modal-header {
  border-bottom: none;
}
.modal-header .filter-header {
  text-align: center;
  font-size: medium;
  width: 100%;
}

.modal-lg,
.modal-md,
.modal-sm {
  width: 60%;
}
.modal-lg .modal-footer,
.modal-md .modal-footer,
.modal-sm .modal-footer {
  border-top: none;
}
.modal-lg .modal-footer .apply-button,
.modal-md .modal-footer .apply-button,
.modal-sm .modal-footer .apply-button {
  background: forestgreen;
  border: none;
  border-radius: 0px;
  color: white;
}
.modal-lg .modal-footer .reset-button,
.modal-md .modal-footer .reset-button,
.modal-sm .modal-footer .reset-button {
  background: gray;
  border: none;
  border-radius: 0px;
  color: white;
}
.modal-lg .modal-body,
.modal-md .modal-body,
.modal-sm .modal-body {
  padding-top: 0px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0px;
}
.modal-lg .modal-body .divisions-list,
.modal-md .modal-body .divisions-list,
.modal-sm .modal-body .divisions-list {
  display: flex;
}
.modal-lg .modal-body .divisions-list .disabled,
.modal-md .modal-body .divisions-list .disabled,
.modal-sm .modal-body .divisions-list .disabled {
  color: #999;
  cursor: not-allowed;
}
.modal-lg .modal-body .divisions-list .disabled ul li:hover,
.modal-md .modal-body .divisions-list .disabled ul li:hover,
.modal-sm .modal-body .divisions-list .disabled ul li:hover {
  cursor: not-allowed !important;
  background: white !important;
}
.modal-lg .modal-body .divisions-list .division-list,
.modal-md .modal-body .divisions-list .division-list,
.modal-sm .modal-body .divisions-list .division-list {
  text-align: center;
  border-right: 1px solid lightgray;
  padding-top: 15px;
}
.modal-lg .modal-body .divisions-list .division-list:first-child:nth-last-child(1),
.modal-md .modal-body .divisions-list .division-list:first-child:nth-last-child(1),
.modal-sm .modal-body .divisions-list .division-list:first-child:nth-last-child(1) {
  border-right: 0px;
}
.modal-lg .modal-body .divisions-list .division-list ul,
.modal-md .modal-body .divisions-list .division-list ul,
.modal-sm .modal-body .divisions-list .division-list ul {
  list-style: none;
  text-align: justify;
  margin-top: 10px;
  max-height: 350px !important;
  overflow-y: auto;
}
.modal-lg .modal-body .divisions-list .division-list ul li,
.modal-md .modal-body .divisions-list .division-list ul li,
.modal-sm .modal-body .divisions-list .division-list ul li {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -40px;
}
.modal-lg .modal-body .divisions-list .division-list ul li.location-select,
.modal-md .modal-body .divisions-list .division-list ul li.location-select,
.modal-sm .modal-body .divisions-list .division-list ul li.location-select {
  background: #bbb;
}
.modal-lg .modal-body .divisions-list .division-list ul li:hover,
.modal-md .modal-body .divisions-list .division-list ul li:hover,
.modal-sm .modal-body .divisions-list .division-list ul li:hover {
  cursor: context-menu;
  background: #ddd;
}
.modal-lg .modal-body .division-titles,
.modal-md .modal-body .division-titles,
.modal-sm .modal-body .division-titles {
  text-align: center;
  width: 100%;
  font-weight: 500;
}
.modal-lg .modal-body .division-titles .division-title,
.modal-md .modal-body .division-titles .division-title,
.modal-sm .modal-body .division-titles .division-title {
  border-right: 1px solid lightgray;
}
.modal-lg .modal-body .selected-filters,
.modal-md .modal-body .selected-filters,
.modal-sm .modal-body .selected-filters {
  padding: 5px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 11px;
  padding-top: 1px;
  min-height: 35px !important;
}
.modal-lg .modal-body .selected-filters ul li.filter,
.modal-md .modal-body .selected-filters ul li.filter,
.modal-sm .modal-body .selected-filters ul li.filter {
  color: white;
  background: dodgerblue;
  padding: 4px;
  margin: 3px;
  display: inline-block;
}
.modal-lg .modal-body .selected-filters ul li.filter .glyphicon,
.modal-md .modal-body .selected-filters ul li.filter .glyphicon,
.modal-sm .modal-body .selected-filters ul li.filter .glyphicon {
  margin-left: 5px;
  cursor: pointer;
}

.panel {
  border: 0;
  border-radius: 0;
  background: white;
}
.panel .panel-header {
  font-weight: bold;
}
.panel .panel-body {
  border-left: 6px solid transparent;
}
.panel .panel-body .media {
  margin-top: 0;
  width: 100%;
  display: inline-block;
  overflow: visible;
}
.panel .panel-body .media .counter {
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: top;
  border-radius: 50%;
  background-color: #69bc6c;
  margin-right: 5px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: white;
}
.panel .panel-body .media .counter.red {
  background-color: rgb(255, 64, 64);
}
.panel .panel-body .media .counter.orange {
  background-color: rgb(255, 160, 55);
}
.panel .panel-body .media .counter.green {
  background-color: rgb(75, 174, 79);
}
.panel .panel-body .media .data {
  display: inline-block;
  padding-top: 5px;
  width: calc(100% - 55px);
}
.panel .panel-body .media .data > div {
  margin-left: 5px;
  font-size: 15px;
  margin-bottom: 3px;
}
.panel .panel-body .media .data > div .dropdown button {
  border: 0;
  box-shadow: none;
  background-color: transparent;
}
.panel .panel-body .media .data > div .dropdown button:hover {
  background-color: transparent;
}
.panel .panel-body .media .data > div .dropdown button:active, .panel .panel-body .media .data > div .dropdown button:focus {
  box-shadow: none;
  background-color: transparent;
}
.panel .panel-body .media .data > div .dropdown button .dropdown-toggle {
  background-color: transparent;
  box-shadow: none;
}
.panel .panel-body .media .data > div .dropdown ul {
  padding: 0;
}
.panel .panel-body .media .data > div .dropdown ul li {
  float: none;
  margin: 0;
}
.panel .panel-body .media .data > div .dropdown ul li a:focus {
  outline: 0;
}
.panel .panel-body .media .data p {
  font-size: 14px;
}
.panel .panel-body .media .data p:first-child {
  font-size: 16px;
  padding-left: 5px;
  margin-bottom: 5px;
}
.panel .panel-body .media .data ul {
  padding: 0 0 0 5px;
  list-style: none;
}
.panel .panel-body .media .data ul li {
  float: left;
  margin-right: 15px;
}
.panel .panel-body .media .data ul li:last-child {
  margin-right: 0;
}
.panel .panel-body .media .data ul li label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  word-wrap: break-word;
  vertical-align: top;
}
.panel .panel-body .media .data .fa {
  font-size: 14px;
  margin-right: 5px;
}
.panel .panel-body .media .data .fa.green {
  color: rgb(75, 174, 79);
}
.panel .panel-body .media .data .fa.blue {
  color: #32b0ae;
}
.panel .panel-body .media .data .badge {
  float: right;
  background-color: rgb(95, 124, 138);
}
.panel .panel-body .time {
  display: inline-block;
  float: right;
  font-size: 12px;
}
.panel .panel-body .bottom-list {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}
.panel .panel-body .bottom-list li {
  display: inline-block;
  font-size: 13px;
  padding: 3px 6px;
  margin-bottom: 2px;
  margin-right: 5px;
}
.panel .panel-body .bottom-list li .fa {
  margin-right: 3px;
  color: rgb(106.5, 106.5, 106.5);
}

.feedback .panel:last-child {
  margin-bottom: 0;
}

.feedback-list .list-stats-card {
  color: --primary-color;
  padding: 10px 0;
  text-align: center;
  background: white;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  z-index: 1;
}
.feedback-list .content-wrapper {
  height: auto !important;
}
.feedback-list .panel {
  margin-bottom: 15px;
  cursor: pointer;
  border-left: 5px solid transparent;
}
.feedback-list .panel.selected {
  border-left: 5px solid rgb(231.45, 231.45, 231.45);
  box-shadow: 0 2px 4px rgb(224.4272727273, 225.5, 226.5727272727);
  position: relative;
}
.feedback-list .panel.unprocessed:not(.selected) {
  border-left: 5px solid var(--primary-color);
}
.feedback-list .panel .badge-primary {
  display: inline-block;
  float: right;
  background: var(--primary-color);
  color: white;
  padding: 2px 5px;
  font-size: 12px;
}
.feedback-list .custom-scroll {
  position: relative;
  left: auto;
  right: auto;
  bottom: auto;
  top: auto;
}
@media (min-width: 992px) {
  .feedback-list .custom-scroll {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
  }
}

.details-modal .list-group {
  margin-bottom: 0;
}
.details-modal .list-group h5 {
  margin-top: 0;
  font-weight: 500;
}
.details-modal .list-group > .list-group-item {
  border: 0;
  padding: 5px 10px;
}
.details-modal .list-group > .list-group-item > .list-group {
  margin-bottom: 10px;
}
.details-modal .list-group > .list-group-item > .list-group .list-group-item {
  display: block;
  border: 0;
  position: relative;
  border-radius: 0;
  padding-left: 20px;
  vertical-align: top;
}
.details-modal .list-group > .list-group-item > .list-group .list-group-item:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #32b0ae;
  border: 1px solid #32b0ae;
  left: 0;
  top: 8px;
}
.details-modal .list-group > .list-group-item > .list-group .list-group-item.active {
  background-color: transparent;
  color: #1c1a24;
}
.details-modal .list-group > .list-group-item > .list-group .list-group-item.active:before {
  background-color: #32b0ae;
}
.details-modal .list-group .suboptions li {
  display: block !important;
  width: 100% !important;
}

.details {
  overflow: auto;
  overflow-x: hidden;
  padding-left: 0;
}
.details.public-view {
  padding-left: 15px;
}
.details .smooth {
  scroll-behavior: smooth;
}
@media (min-width: 992px) {
  .details {
    display: block;
  }
}
@media (max-width: 769px) {
  .details {
    padding-right: 0;
  }
}
.details.scollbar {
  overflow: scroll hidden;
  max-height: 85vh;
  white-space: nowrap;
}
.details.scollbar:before {
  box-shadow: inset -11px 0px 24px -9px rgba(0, 0, 0, 0.75);
}
.details.full-height {
  max-height: 100vh;
}
.details .card {
  box-shadow: 0px 0px 2px 2px #ddd;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.details .card:not(:first-child) {
  margin-left: 5px;
}
@media (max-width: 992px) {
  .details .card {
    width: 100%;
  }
}
.details .card.full-height {
  height: 100vh;
}
.details .panel {
  margin-bottom: 0;
  min-height: calc(100vh - 100px);
  white-space: normal;
}
.details .panel .content-wrapper {
  padding-top: 0;
}
.details .panel .panel-body {
  border-top: 1px solid #eaeaea;
}
@media screen and (max-width: 1400px) {
  .details .panel .panel-body {
    padding: 15px 5px;
  }
}
.details .panel .panel-body:first-child {
  border: 0;
}
.details .panel .panel-body span {
  font-weight: 400;
  margin-bottom: 10px;
}
.details .panel .panel-body span.error.badge-danger {
  float: right;
  padding: 10px 20px;
  margin: 0;
  background: rgb(255, 64, 64);
  color: white;
  box-shadow: -1px 3px 8px 0px grey;
  font-size: 75%;
  font-weight: 700;
  border-radius: 2px;
}
.details .panel .panel-body span.control-label {
  font-weight: 600;
}
.details .panel .panel-body .form-group {
  margin-bottom: 20px;
}
.details .panel .panel-body hr {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.details .panel .panel-body .bottom-list {
  margin: 16px 0 0 0;
}
.details .list-group {
  margin-bottom: 0;
}
.details .list-group h5 {
  margin-top: 0;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.details .list-group > .list-group-item {
  border: 0;
  padding: 5px 10px;
  width: 100%;
}
.details .list-group > .list-group-item > .list-group {
  margin-bottom: 10px;
}
.details .list-group > .list-group-item > .list-group .list-group-item {
  display: inline-block;
  border: 0;
  width: 100%;
  position: relative;
  border-radius: 0;
  padding-left: 20px;
  vertical-align: top;
}
.details .list-group > .list-group-item > .list-group .list-group-item:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #32b0ae;
  border: 1px solid #32b0ae;
  left: 0;
  top: 8px;
}
.details .list-group > .list-group-item > .list-group .list-group-item.active {
  background-color: transparent;
  color: #1c1a24;
}
.details .list-group > .list-group-item > .list-group .list-group-item.active:before {
  background-color: #32b0ae;
}
.details .list-group .suboptions li {
  display: block !important;
  width: 100% !important;
}

.no-selection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-selection h2 {
  font-weight: bold;
  color: rgb(208.9375, 208.9375, 200.5625);
  text-shadow: 0 1px 0 white;
}
.no-selection h2 .fa {
  color: #ffe400;
  margin-right: 15px;
}

.feedbacks {
  margin-top: 20px;
}
.feedbacks .select-holder {
  background: #fff;
  padding: 25px 25px 10px 25px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  max-width: 485px;
  margin: 0 auto 20px;
}
.feedbacks .select-holder .select-block {
  max-width: 300px;
  margin: 0 auto;
}
.feedbacks .select-holder .select-block .field {
  margin: 0 0 15px;
}
.feedbacks .select-holder .select-block .field .label {
  font-weight: normal;
  color: #333333;
  font-size: 14px;
  display: inline-block;
  width: 110px;
}
@media screen and (max-width: 1400px) {
  .feedbacks .select-holder .select-block .field .label {
    width: 90px;
  }
}
.feedbacks .select-holder .select-block .field .select {
  display: inline-block;
  vertical-align: middle;
  min-width: 168px;
  height: 43px;
  border: 2px solid #efefef;
  color: #1a1a1a;
  background: #fff;
  outline: none;
  position: relative;
  text-align: left;
}
.feedbacks .select-holder .select-block .field .select button {
  background: inherit;
  color: inherit;
  border: 0;
  padding: 0 25px;
  width: 100%;
  text-align: left;
  line-height: 2.7;
}
.feedbacks .select-holder .select-block .field .select button:after {
  position: absolute;
  top: 45%;
  right: 10px;
}
@media screen and (max-width: 1400px) {
  .feedbacks .select-holder .select-block .field .select {
    width: 140;
  }
}
.feedbacks .select-holder b {
  font-size: 13px;
  font-weight: 500;
  color: rgb(119.0090909091, 123.5, 127.9909090909);
  float: left;
}
.feedbacks .select-holder .spam-toggle {
  display: inline-flex;
  padding: 4px 10px;
}
.feedbacks .select-holder .spam-toggle .spam-title {
  margin-right: 3px;
  font-size: 12px;
  line-height: 1.5;
}
.feedbacks .select-holder .dropdown {
  margin-left: 10px;
}
.feedbacks .select-holder .dropdown button {
  border-color: #eaeaea;
  border-radius: 0;
  padding: 4px 10px;
}
.feedbacks .select-holder .dropdown button:focus {
  border-color: #eaeaea;
  box-shadow: none;
  background-color: #eaeaea;
}
.feedbacks .select-holder .dropdown .dropdown-menu {
  min-width: 168px;
  padding: 0 25px;
  font-size: 12px;
  margin-top: 0;
  right: 0;
  left: auto;
  border-radius: 0;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 5px #eaeaea;
  padding: 0;
}
.feedbacks .select-holder .dropdown .dropdown-menu li {
  border-bottom: 1px solid #f4f4f2;
}

.feedback-detail-transaction-box {
  margin: 15px 0px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1400px) {
  .feedback-detail-transaction-box {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  .feedback-detail-transaction-box {
    flex-flow: column;
  }
  .feedback-detail-transaction-box div {
    margin-top: 10px;
    padding-left: 15px;
  }
}

a:focus,
a:active,
a:hover,
a:visited,
button:focus,
button:active,
button:hover,
button:visited,
input:focus,
input:active,
input:hover,
input:visited,
textarea:focus,
textarea:active,
textarea:hover,
textarea:visited,
video:focus,
video:active,
video:hover,
video:visited,
audio:focus,
audio:active,
audio:hover,
audio:visited,
img:focus,
img:active,
img:hover,
img:visited {
  outline: none;
}

.input-1 {
  background-color: white;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  padding: 10px 0 15px 56px;
  color: black;
  height: auto;
  font-size: 16px;
  position: relative;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group .fa {
  font-size: 16px;
  position: absolute;
  top: 13px;
  left: 15px;
  z-index: 1;
}
.form-group .label .grey-text {
  font-size: 13px;
  color: grey;
}

.button-1 {
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px;
}
.button-1:focus {
  outline: 0;
}
.button-1:hover {
  background-color: rgb(0, 57.7894736842, 109.8);
}

.search-form {
  display: inline-flex;
  width: 100%;
  position: relative;
}
.search-form input {
  height: 42px;
  width: 90%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}
.search-form button {
  background: rgb(0, 90, 171);
  color: white;
  cursor: pointer;
  border: 1px solid rgb(0, 90, 171);
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.search-form button:active, .search-form button:focus, .search-form button:hover, .search-form button[disabled], .search-form button[disabled]:hover {
  background: rgb(0, 84.6315789474, 160.8);
  color: white;
  border: 1px solid rgb(0, 90, 171);
}
.search-form .cross-icon {
  position: absolute;
  right: 50px;
  top: 14px;
  color: rgb(191.25, 191.25, 191.25);
  cursor: pointer;
}
.search-form .cross-icon:hover {
  color: rgb(127.5, 127.5, 127.5);
}

.primary-btn {
  padding: 10px 20px;
  background: rgb(0, 90, 171);
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
}
.primary-btn:hover, .primary-btn:active, .primary-btn:visited, .primary-btn:focus {
  color: #fff;
  background: rgb(0, 57.7894736842, 109.8);
  text-decoration: none;
}

.custom-dropdown {
  color: #555;
  line-height: 1.42857143;
}
.custom-dropdown .header {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 5px 6px 12px;
  min-height: 34px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}
.custom-dropdown .header:after {
  content: "▾";
  font-size: 12px;
}
.custom-dropdown .custom-dropdown-menu {
  padding: 5px 5px;
  border: 1px solid #ccc;
  position: absolute;
  z-index: 5;
  width: 100%;
  background: white;
  display: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
.custom-dropdown .custom-dropdown-items {
  list-style: none;
  margin: 0;
  padding: 5px 0;
}
.custom-dropdown .custom-dropdown-items li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.custom-dropdown .custom-dropdown-items li:hover,
.custom-dropdown .custom-dropdown-items .selected {
  background: #3297fd;
  color: white;
}
.custom-dropdown .sub-items {
  padding-left: 10px;
}
.custom-dropdown.disabled {
  cursor: not-allowed;
  background: rgb(127.5, 127.5, 127.5);
}
.custom-dropdown.open .overlay {
  display: block;
  background: transparent;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.custom-dropdown.open .custom-dropdown-menu,
.custom-dropdown.open .header {
  border: 1px solid #66afe9;
  outline: none;
}
.custom-dropdown.open .header {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-dropdown.open .custom-dropdown-menu {
  display: block;
  box-shadow: 5px 5px 20px 1px #ddd;
}

.load-more.vertical {
  transform: rotate(-90deg);
  display: inline-block;
  vertical-align: middle;
}

.scale {
  padding: 10px;
}
.scale .scale-container {
  text-align: center;
  width: 100%;
}
.scale-value {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 25px;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  position: relative;
}
.scale-value:first-child:nth-last-child(4), .scale-value:first-child:nth-last-child(4) ~ .scale-value {
  width: 25%;
}
.scale-value:first-child:nth-last-child(12), .scale-value:first-child:nth-last-child(12) ~ .scale-value {
  width: 8%;
}
@media only screen and (max-width: 1400px) {
  .scale-value {
    width: 25px;
  }
}
.scale-value:first-child {
  border-left: 1px solid grey;
}
.scale-value .text {
  position: absolute;
  bottom: -5px;
  right: -4px;
  background: white;
}

.scale .base {
  padding-top: 10px;
  width: 100%;
}
.scale .base .left-value,
.scale .base .right-value {
  color: black;
  display: inline-block;
  font-size: 12px;
}
.scale .base .left-value {
  width: 105px;
}
.scale .base .right-value {
  float: right;
  width: 120px;
}

.highlight {
  color: #32b0ae !important;
  font-weight: bold;
  cursor: pointer;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

.download-button-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  background: var(--primary-color);
  margin: 0;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  z-index: 10;
}
.download-button-wrapper.downloaded {
  animation: largeBtn 0.2s ease-in 1.3s forwards;
}
.download-button-wrapper.downloaded .button-filler {
  animation: fillColor 0.3s ease-in 1s forwards;
}
.download-button-wrapper.downloaded .download .arrow-wrapper {
  animation: freefallArrow 0.3s ease-in forwards;
}
.download-button-wrapper.downloaded .download .arrow-stem {
  animation: freefallStem 0.3s ease-in 0.3s forwards;
}
.download-button-wrapper.downloaded .download .arrow-left,
.download-button-wrapper.downloaded .download .arrow-right {
  animation: freefallEdge 0.3s ease-in 0.6s forwards;
}
.download-button-wrapper .button-filler {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 0px;
  background-color: #69bc6c;
  z-index: -1;
}
.download-button-wrapper .text {
  display: none;
  color: white;
  text-align: center;
  position: relative;
  top: 3px;
}
.download-button-wrapper .download {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  color: #fff;
  margin: auto;
}
.download-button-wrapper .download .arrow-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 11.5px;
  display: block;
  width: 18px;
  height: 27px;
  margin-right: auto;
  margin-left: auto;
}
.download-button-wrapper .download .arrow-wrapper .arrow-left {
  position: absolute;
  left: 2px;
  bottom: 2px;
  width: 8px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
  transform: rotate(45deg);
}
.download-button-wrapper .download .arrow-wrapper .arrow-stem {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  width: 2px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 1px;
  background-color: #fff;
}
.download-button-wrapper .download .arrow-wrapper .arrow-right {
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 8px;
  height: 2px;
  background-color: #fff;
  transform: rotate(-45deg);
}

.slider-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.slider-preview .backdrop {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: unset;
}
.slider-preview .media {
  width: 50vw;
  height: 70vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  display: block;
}
.slider-preview .media .full-size {
  height: calc(100% - 100px);
}
.slider-preview .media .full-size audio,
.slider-preview .media .full-size video,
.slider-preview .media .full-size img {
  width: 100%;
  height: 100%;
}
.slider-preview .media .full-size .media-container {
  display: block;
  height: 100%;
  width: 100%;
}
.slider-preview .media .full-size .media-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}
.slider-preview .media .thumbnail-list {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100px;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}
.slider-preview .media .thumbnail-list li {
  width: 95px;
  height: 95px;
  margin-right: 5px;
  display: inline-block;
  border: 2px solid transparent;
}
.slider-preview .media .thumbnail-list li.active {
  border: 2px solid royalblue;
}
.slider-preview .media .thumbnail-list li:hover, .slider-preview .media .thumbnail-list li:focus {
  border: 2px solid whitesmoke;
}
.slider-preview .media .thumbnail-list li span {
  display: block;
  width: 100%;
  height: 100%;
}
.slider-preview .media .thumbnail-list li audio,
.slider-preview .media .thumbnail-list li video,
.slider-preview .media .thumbnail-list li img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.media-container .thumb {
  display: inline-block;
}
.media-container.small video,
.media-container.small img {
  max-width: 100%;
}
.media-container.small audio {
  width: 100%;
}
.media-container audio,
.media-container video,
.media-container img {
  max-width: 100%;
  height: 140px;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}
.media-container audio {
  width: calc(50% - 5px);
  height: 50px;
}
.media-container .video-container {
  position: relative;
  width: 100%;
}
.media-container .video-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-container .video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.user-account {
  display: inline-block;
  vertical-align: middle;
}
.user-account > * {
  padding: 5px 0 5px 0;
  display: table-cell;
  vertical-align: middle;
}
.user-account span.user-img span {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  line-height: 45px;
  text-align: center;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.7;
  background: var(--secondary-color);
  border-radius: 50%;
  padding: 6% 0;
}
.user-account span.user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  object-fit: contain;
  object-position: center;
}
.user-account span.user-name {
  min-width: 70px;
  color: rgb(0, 90, 171);
  font-size: medium;
  font-weight: bold;
}
.user-account span.user-name span {
  display: block;
  font-weight: normal;
  font-size: small;
  text-align: left;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.bold {
  font-weight: bold;
}

.comment {
  min-height: 20px;
  padding: 9px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.comment h4 {
  font-size: 18px;
}

.panel .panel-body {
  padding: 15px;
}

button,
a,
[class*=dropdown] {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
           supported by Chrome and Opera */
}

.rcs-custom-scroll .rcs-inner-handle {
  background-color: grey;
}

.invoices-modal .error {
  text-align: center;
  color: rgb(157, 157, 157);
}
.invoices-modal .invoice-loadmore {
  padding: 10px 0;
}
.invoices-modal .invoice-header {
  display: inline-block;
  width: 95%;
}
@media (max-width: 769px) {
  .invoices-modal .modal-body {
    font-size: 12px;
  }
  .invoices-modal .content-wrapper {
    height: calc(100% + 10px) !important;
  }
}
.invoices-modal .invoice-table {
  text-align: center;
}
.invoices-modal .invoice-table th {
  background: white;
  position: sticky;
  top: 0;
  text-align: center;
}
.invoices-modal .invoice-table tbody tr:hover {
  cursor: pointer;
  background: #f4f4f2;
  color: rgb(0, 90, 171);
}
.invoices-modal .back-button {
  font-size: 18px;
  cursor: pointer;
}
.invoices-modal .not-found {
  text-align: center;
}
.invoices-modal .recepit-header {
  border-top: 2px solid #ddd;
}
.invoices-modal .invoice-details:not(:first-child) {
  padding-top: 30px;
  border-top: 1px dotted black;
}
.invoices-modal .invoice-details .table-heads {
  width: 76%;
  display: inline-block;
  padding-left: 15px;
}
@media (max-width: 769px) {
  .invoices-modal .invoice-details .table-heads {
    width: 60%;
  }
}
.invoices-modal .invoice-details .table-body {
  display: inline-block;
  width: 24%;
  font-weight: normal;
}
@media (max-width: 769px) {
  .invoices-modal .invoice-details .table-body {
    width: 40%;
  }
}
.invoices-modal .invoice-details thead tr:nth-child(3),
.invoices-modal .invoice-details tbody tr,
.invoices-modal .invoice-details tfoot tr {
  padding: 10px 5px;
}
.invoices-modal .invoice-details .total {
  font-weight: bolder;
  text-align: right;
  padding: 5px 35px;
}
.invoices-modal .invoice-details .receipt-footer {
  border-bottom: 2px solid #ddd;
  background: #c6c8ca;
  color: black;
}
.invoices-modal .invoice-details .row-no {
  border-right: 2px solid #ddd;
  text-align: center;
}
.invoices-modal .invoice-details .item-name {
  width: 55%;
  text-align: left;
}
.invoices-modal .invoice-details .code-name {
  width: 15%;
  text-align: center;
}
.invoices-modal .invoice-details .price,
.invoices-modal .invoice-details .total-item-price {
  width: 95px;
  text-align: center;
}
.invoices-modal .invoice-details .quantity {
  width: 70px;
  text-align: center;
}
.invoices-modal .modal-footer .search-form {
  width: 40%;
}
.invoices-modal .table-row {
  border-bottom: 2px solid #ddd;
  text-align: center;
}
@media (max-width: 769px) {
  .invoices-modal .modal-dialog {
    width: 100%;
    margin: 0;
  }
  .invoices-modal .custom-scroll .content-wrapper {
    overflow-x: scroll;
  }
}

.invoice-icon {
  color: rgb(157, 157, 157);
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.invoice-icon:hover {
  color: rgb(0, 90, 171);
}

.lazy-load-container {
  display: inline-block;
  width: 60px;
  position: relative;
}

.feedback-dropdown {
  float: right;
  color: grey;
}
.feedback-dropdown .up {
  position: absolute;
  top: -5px;
  left: 18px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  box-shadow: 0 0 4px 2px grey;
  background: white;
}
.feedback-dropdown .up:after {
  content: " ";
  background: white;
  width: 20px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: -1px;
  transform: rotate(-45deg);
}
.feedback-dropdown .bottom {
  position: absolute;
  top: auto;
  bottom: -5px;
  left: 18px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  box-shadow: 0 0 4px 2px grey;
  background: white;
}
.feedback-dropdown .bottom:after {
  content: " ";
  background: white;
  width: 20px;
  height: 10px;
  position: absolute;
  top: -4px;
  left: -8px;
  transform: rotate(-45deg);
}
.feedback-dropdown .left {
  position: absolute;
  left: -5px;
  top: 18px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  box-shadow: 0 0 4px 2px grey;
  background: white;
}
.feedback-dropdown .left:after {
  content: " ";
  background: white;
  width: 10px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: 3px;
  transform: rotate(-45deg);
}
.feedback-dropdown .right {
  position: absolute;
  right: -5px;
  top: 18px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  box-shadow: 0 0 4px 2px grey;
  background: white;
}
.feedback-dropdown .right:after {
  content: " ";
  background: white;
  width: 10px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: -5px;
  transform: rotate(-45deg);
}
.feedback-dropdown .menu {
  box-shadow: 0px 0px 6px 2px grey;
  background: white;
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: -18px;
  z-index: 1;
  padding: 10px 0;
  border-radius: 15px;
  width: 200px;
}
.feedback-dropdown .menu.opens-upside {
  bottom: 30px;
  top: auto;
}
.feedback-dropdown .menu.small {
  width: 150px;
}
.feedback-dropdown .menu.scale-menu {
  width: 400px;
}
.feedback-dropdown .menu.scale-menu.small {
  width: 250px;
}
.feedback-dropdown .menu.sub {
  left: calc(100% - 10px);
  top: 0;
  width: 100%;
}
.feedback-dropdown .menu.sub.opensleft {
  left: calc(-100% + 10px);
}
.feedback-dropdown .menu.sub.opensright {
  right: calc(-100% + 10px);
}
.feedback-dropdown .menu.active {
  display: block;
}
.feedback-dropdown .menu .options {
  padding-top: 5px;
  list-style-type: none;
  padding: 0px;
}
.feedback-dropdown .menu .options .icon {
  float: right;
  padding: 3px 0;
}
.feedback-dropdown .menu .options .option {
  border-bottom: 1px solid #cdcdcc;
  padding: 5px 12px;
  white-space: normal;
  text-align: center;
  color: #cdcdcc;
  position: relative;
}
.feedback-dropdown .menu .options .option:last-child {
  border-bottom: 0;
}

.chat-box {
  font-size: 14px;
  margin: 0px 20px 20px 20px;
}
.chat-box .chat-list-container {
  padding: 0 20px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}
.chat-box .chat-list-container .message {
  background: #5894c8;
  padding: 10px;
  color: white;
  margin: 10px 0;
  position: relative;
  display: inline-block;
  width: 80%;
  float: right;
}
.chat-box .chat-list-container .message:after {
  background: url(../img/arrow-right.jpg) no-repeat;
  width: 12px;
  height: 20px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  content: "";
}
.chat-box .chat-list-container .message.user {
  background: #f5f5f5;
  color: #666;
  float: left;
}
.chat-box .chat-list-container .message.user:after {
  background: url(../img/arrow-left.jpg) no-repeat;
  width: 10px;
  height: 19px;
  right: 100%;
  left: auto;
}
.chat-box .chat-list-container .message .timestamp {
  float: right;
  font-size: 12px;
  margin: 3px 0 0;
}
.chat-box .chat-list-container .message .timestamp .fa {
  margin-left: 5px;
}
.chat-box .text-area-contianer {
  border: 1px solid var(--primary-color);
  border-radius: 3px;
  margin: -5px 0;
  position: relative;
}
.chat-box .text-area-contianer textarea {
  height: 100%;
  width: 100%;
  border: 0;
  resize: none;
}
.chat-box .text-area-contianer textarea:hover, .chat-box .text-area-contianer textarea:active, .chat-box .text-area-contianer textarea:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}
.chat-box .text-area-contianer .button-container {
  text-align: right;
  margin: 0px 0px 10px 0px;
}
.chat-box .text-area-contianer .button-container .fa {
  margin-right: 10px;
}
.chat-box .text-area-contianer .button-container .btn-info:last-of-type {
  margin-left: 10px;
  margin-right: 10px;
}
.chat-box .text-area-contianer .button-container button:first-of-type,
.chat-box .text-area-contianer .button-container button:last-of-type {
  border-color: var(--primary-color);
  border-radius: 0px;
}
.chat-box .text-area-contianer .button-container button:first-of-type:active, .chat-box .text-area-contianer .button-container button:first-of-type:focus, .chat-box .text-area-contianer .button-container button:first-of-type:visited,
.chat-box .text-area-contianer .button-container button:last-of-type:active,
.chat-box .text-area-contianer .button-container button:last-of-type:focus,
.chat-box .text-area-contianer .button-container button:last-of-type:visited {
  border-color: var(--primary-color);
}
.chat-box .text-area-contianer .button-container button:first-of-type .caret,
.chat-box .text-area-contianer .button-container button:last-of-type .caret {
  border: 4px solid;
  border-color: transparent transparent white transparent;
  border-bottom: dashed;
  margin: -4px 0 0;
}
.chat-box .text-area-contianer .button-container button:first-of-type {
  background: white;
  color: var(--primary-color);
}
.chat-box .text-area-contianer .button-container button:first-of-type:active, .chat-box .text-area-contianer .button-container button:first-of-type:focus, .chat-box .text-area-contianer .button-container button:first-of-type:visited {
  background: white;
}
.chat-box .text-area-contianer .button-container button:last-of-type {
  background: var(--primary-color);
  color: white;
}
.chat-box .text-area-contianer .button-container button:last-of-type:active, .chat-box .text-area-contianer .button-container button:last-of-type:focus, .chat-box .text-area-contianer .button-container button:last-of-type:visited {
  background: var(--primary-color);
}
.chat-box .text-area-contianer .button-container .dropdown-menu {
  left: unset !important;
  right: calc(100% - 29px) !important;
  border: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  border-radius: 3px 3px 0 0;
  padding: 0;
}
.chat-box .text-area-contianer .button-container .dropdown-menu li {
  border-top: 1px solid #a7a7a7;
}
.chat-box .text-area-contianer .button-container .dropdown-menu li:first-child {
  border: 0;
}
.chat-box .text-area-contianer .button-container .dropdown-menu li a {
  padding: 8px 16px;
}
.chat-box .text-area-contianer .button-container .dropdown-menu li a:hover {
  color: #2d4e71;
}
.chat-box .text-area-contianer .button-container .dropdown-item {
  color: var(--primary-color);
}

.dropup .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  min-width: 137px !important;
}
.dropup .dropdown-menu {
  bottom: 100% !important;
  top: unset !important;
  border: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  border-radius: 3px 3px 0 0;
  padding: 0;
}
.dropup .dropdown-menu li {
  border-top: 1px solid #a7a7a7;
}
.dropup .dropdown-menu li:first-child {
  border: 0;
}
.dropup .dropdown-menu li a {
  padding: 8px 16px;
}
.dropup .dropdown-menu li a:hover {
  color: #2d4e71;
}

.notification-button {
  margin-left: 5px;
  vertical-align: middle;
  box-shadow: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  position: relative;
  color: white;
  margin-right: 20px;
}
.notification-button i {
  font-size: 16px;
}
.notification-button.wrapper__button {
  color: #ffa037;
}
.notification-button img {
  position: relative;
  top: -2px;
}

.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.wrapper__button {
  color: #ffa037;
  position: relative;
}
.wrapper__button .counter {
  height: 15px;
  width: 15px;
  background-color: #d50d1e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: absolute;
  top: -4px;
  right: 4px;
  border-radius: 50%;
}

.notifications {
  position: absolute;
  box-shadow: 0 1px 10px 1px rgba(1, 1, 1, 0.1);
  width: 400px;
  background-color: #fff;
  z-index: 99;
  cursor: initial;
}
.notifications.menu:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 15px solid #fff;
  border-top: 15px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  left: calc(50% + 75px);
  top: -30px;
}
.notifications a {
  text-decoration: none;
  color: #000;
}
.notifications a:focus, .notifications a:active, .notifications a:hover, .notifications a:visited {
  text-decoration: none;
  color: #000;
}
.notifications--card {
  position: unset;
  top: unset;
  right: unset;
  margin-right: 10px;
  padding: 0px;
  flex: 0 0 23%;
  float: left;
  height: calc(100vh - 92px);
}
@media (min-width: 992px) {
  .notifications--card {
    width: calc(25% - 10px);
  }
}
.notifications--card .notifications--list {
  height: calc(100vh - 100px);
}
.notifications--heading {
  background-color: #fff;
  border-bottom-color: #808080;
  color: #333;
  box-shadow: 0 3px 3px 1px #c7c6c6;
  padding: 10px 0;
  position: relative;
}
.notifications--heading h5 {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
}
.notifications--heading .up {
  height: 30px;
  width: 30px;
  margin: -20px auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.notifications--list {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  color: #707070;
}
.notifications--list .holder.new {
  background-color: lightgrey;
}
.notifications--list .holder.new .green-dot {
  content: "";
  height: 10px;
  width: 10px;
  background-color: #69bc6c;
  border-radius: 50%;
  vertical-align: middle;
}
.notifications--list .holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-color: #c7c6c6;
  border-bottom-width: 1px;
  padding: 12px 8px;
}
.notifications--list .holder:hover {
  background-color: #c7c6c6;
}
.notifications--list .holder.active {
  background-color: #bbb;
}
.notifications--list .holder .avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.notifications--list .holder .info {
  width: 82%;
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notifications--list .holder .info .type {
  font-size: 10.7px;
  width: 100%;
  text-align: left;
  margin: 0;
}
.notifications--list .holder .info .date {
  font-size: 9px;
  color: #808080;
  margin-top: 3px;
  text-align: left;
  margin: 0;
}
.notifications--list .holder .tag {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #69bc6c;
  margin-left: 10px;
}
.notifications .notifications--button {
  align-content: end;
  background-color: #fff;
  box-shadow: 0 3px 3px 1px #c7c6c6;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}
.notifications .notifications--button .see-all {
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary-color);
  padding: 5px 10px;
}

.insights {
  padding-top: 30px;
  min-height: calc(100% - 160px);
}
.insights .panel {
  margin-bottom: 30px;
}
.insights .panel .line-chart .panel-heading {
  padding: 0 !important;
}
.insight-line-chart-container {
  display: block;
  width: 100%;
}
.insight-line-chart-container .title {
  margin-right: 15px;
}
.insight-line-chart-container .line-header .line-header-nps,
.insight-line-chart-container .line-header .line-header-complaints {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
}
.insight-line-chart-container .line-header .line-header-nps span,
.insight-line-chart-container .line-header .line-header-complaints span {
  float: unset;
}
.insight-line-chart-container .line-header .line-header-nps *,
.insight-line-chart-container .line-header .line-header-complaints * {
  margin-right: 20px;
}
.insight-line-chart-container .line-header .line-header-nps {
  float: left;
}
.insight-line-chart-container .line-header .line-header-complaints {
  float: right;
}

.line-chart-body {
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
}

.insights .panel .panel-body {
  padding: 20px 15px;
  min-height: 275px;
}
.insights .panel.table {
  margin-bottom: 0;
}
.insights .panel.table .panel-body {
  height: calc(40vh - 25px);
}
.insights .customer-stats .panel {
  height: calc(100% - 60px);
}
.insights .customer-stats .panel .panel-body {
  text-align: center;
  font-size: 36px;
  color: #333;
  height: auto;
  min-height: 80px;
}
.insights .table {
  margin-bottom: 0;
}
.insights .table tr td,
.insights .table tr th {
  word-break: break-all;
  width: 35%;
}
.insights .table tr td:last-child,
.insights .table tr th:last-child {
  width: 30%;
}
.insights .custom-scroll {
  padding-bottom: 20px;
}
.insights .filter-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.insights .filter-header .filter .filter-label {
  text-align: left;
  font-size: 11px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}
.insights .filter-header .filter .filter-label .selected-filter {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}
.insights .filter-header .filter .filter-label .selected-filter .breadcrumb-parent:hover {
  cursor: pointer;
  text-decoration: underline;
}
.insights .filter-header .filter .filter-label .dropdown-button .fa {
  margin-left: 10px;
}
.insights .toggle-option {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  color: #003e7d;
  font-weight: bold;
}
.insights .toggle-option .toggle-button {
  display: flex;
  justify-content: flex-end;
}
.insights .comment-entry {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
}
.insights .comment-entry .comment-rating {
  background-color: #2d9e2d;
  padding: 5px 15px;
  color: whitesmoke;
  font-size: 23px;
  font-weight: bold;
}
.insights .comment-entry .comment-text {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  margin: 10px;
  margin-bottom: 0px;
}
.insights .comment-entry .comment-date-time {
  margin-left: 10px;
  color: #737373;
}
.insights .comment-entry .comment-date-time * {
  margin-right: 20px;
}
.insights .comments-container .panel-body {
  min-height: 580px;
  height: 60vh;
}

div#filter-options-list {
  margin-left: 5px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.09);
  padding: 0;
}
div#filter-options-list .popover-content {
  padding: 0;
  border-radius: 0;
}
div#filter-options-list li {
  color: #020202;
  list-style: none;
  cursor: pointer;
}
div#filter-options-list li .option-entry {
  width: 180px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 1px solid #c2c2c4;
}

.customers,
.questionaire {
  padding-top: 30px;
}
.customers .box,
.questionaire .box {
  background: white;
  box-shadow: 5px 5px 0 #e8e8e4;
  padding: 20px;
  min-height: 290px;
}
.customers thead,
.questionaire thead {
  background: inherit;
  font-size: 15px;
  color: #51cbce;
}
.customers th,
.customers td,
.questionaire th,
.questionaire td {
  height: 50px;
  text-align: center;
}
.customers table thead th,
.customers table tbody td,
.questionaire table thead th,
.questionaire table tbody td {
  border: 0;
}
.customers table.table-bordered,
.questionaire table.table-bordered {
  border: 0;
}
.customers .table > tbody + tbody,
.questionaire .table > tbody + tbody {
  border-top: 0;
}
.customers table tbody td,
.questionaire table tbody td {
  border-top: 1px solid #dee2e6 !important;
}
.customers .action-wrapper,
.questionaire .action-wrapper {
  position: relative;
}
.customers .action-wrapper .actions,
.questionaire .action-wrapper .actions {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  width: 70px;
  right: 0;
}
.customers .action-wrapper:hover .actions,
.questionaire .action-wrapper:hover .actions {
  visibility: visible;
}
.customers h4.name,
.questionaire h4.name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
}
.customers .fa-sort,
.questionaire .fa-sort {
  cursor: pointer;
  padding-left: 5px;
  font-size: 18px;
}
.customers span.subtext,
.questionaire span.subtext {
  display: block;
  color: rgb(160, 160, 160);
  font-size: 12px;
}
.customers .contact,
.questionaire .contact {
  font-size: 12px;
  display: block;
}
.customers .email,
.questionaire .email {
  overflow-wrap: anywhere;
}
.customers .link,
.questionaire .link {
  font-size: 12px;
  overflow-wrap: anywhere;
}
.customers .sort,
.questionaire .sort {
  display: inline-block;
  padding: 5px;
}
.customers i.arrow,
.questionaire i.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.customers i.arrow.up,
.questionaire i.arrow.up {
  transform: rotate(-135deg);
}
.customers i.arrow.down,
.questionaire i.arrow.down {
  margin: -8px;
  transform: rotate(45deg);
}
.customers.questionnaire-stats,
.questionaire.questionnaire-stats {
  margin-top: 50px;
}
.customers.questionnaire-stats .table,
.questionaire.questionnaire-stats .table {
  margin-bottom: 25px;
}

.pagination {
  display: flex;
  float: center;
  justify-content: center;
  color: #337ab7;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}
.pagination li {
  display: inline;
  margin-left: -1px;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 0px;
}
.pagination li.active, .pagination li:hover:not(.disabled) {
  z-index: 3;
  color: #fff;
  cursor: pointer;
  background-color: #337ab7;
  border-color: #337ab7;
}
.pagination li > a {
  padding: 6px 12px;
}
.pagination li.disabled {
  cursor: not-allowed;
}
.pagination li.disabled a {
  cursor: not-allowed;
  color: #777;
}

.users {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .users {
    margin-top: 30px;
  }
}
.users .row > [class*=col] {
  padding: 0;
}
@media (min-width: 768px) {
  .users .row > [class*=col] {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.users .row .panel {
  border-radius: 0;
  box-shadow: 5px 5px 0 rgb(232.3125, 232.3125, 228.1875);
  border: 1px solid rgb(232.3125, 232.3125, 228.1875);
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .users .row .panel {
    margin-bottom: 30px;
  }
}
.users .row .panel .panel-body {
  padding: 18px 24px;
  border-left: 0;
  position: relative;
  overflow: hidden;
}
.users .row .panel .panel-body .media {
  height: 100px;
}
.users .row .panel .panel-body .media .media-left {
  padding: 0 0 20px 0;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .users .row .panel .panel-body .media .media-left {
    padding: 0 15px 0 0;
    display: table-cell;
  }
}
.users .row .panel .panel-body .media .media-left .user-img {
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  line-height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
  background: url("../img/user-avatar.png") no-repeat center;
}
@media (min-width: 768px) {
  .users .row .panel .panel-body .media .media-left .user-img {
    margin: 0;
  }
}
.users .row .panel .panel-body .media .media-left .user-img img {
  width: 100%;
  background-size: 100%;
}
.users .row .panel .panel-body .media .media-body {
  vertical-align: middle;
  display: block;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .users .row .panel .panel-body .media .media-body {
    display: table-cell;
    width: 10000px;
    text-align: left;
    padding-bottom: 0;
  }
}
.users .row .panel .panel-body .media .media-body .help-block {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #737373;
}
.users .row .panel .panel-body .media .media-body .help-block span {
  font-weight: normal;
}
.users .row .panel .panel-body .media .media-body p {
  margin-bottom: 3px;
}
.users .row .panel .panel-body .media .media-body p .fa {
  margin-right: 5px;
  text-align: center;
  width: 14px;
}
.users .row .panel .panel-body .media .media-body .media-heading {
  font-size: 18px;
  color: rgb(0, 90, 171);
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .users .row .panel .panel-body .media .media-body .media-heading {
    margin-bottom: 5px;
  }
}
.users .row .panel .panel-body .media .media-body .media-heading span {
  font-size: 14px;
  color: rgb(150.5, 150.5, 131.5);
  margin-left: 3px;
  font-weight: normal;
  display: block;
}
@media (min-width: 768px) {
  .users .row .panel .panel-body .media .media-body .media-heading span {
    display: inline-block;
  }
}

.animated-appear {
  animation: 0.6s fadeInRight;
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes flash {
  0% {
    box-shadow: 0px 0px 6px 3px var(--secondary-color);
  }
  100% {
    box-shadow: 0px 0px 6px 4px var(--secondary-color);
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@keyframes freefallArrow {
  to {
    bottom: 1px;
  }
}
@keyframes freefallStem {
  to {
    height: 0;
  }
}
@keyframes freefallEdge {
  0% {
    bottom: 1px;
  }
  30% {
    transform: rotate(0deg);
    bottom: 1px;
  }
  100% {
    width: 100%;
    transform: rotate(0deg);
    bottom: 1px;
  }
}
@keyframes fillColor {
  to {
    height: 100%;
  }
}
@keyframes largeBtn {
  to {
    width: 130px;
  }
}
.table {
  margin-bottom: 0;
}
.table td,
.table th {
  vertical-align: middle !important;
  word-wrap: break-word;
  border-top: none !important;
}

.benchmarks,
.priority-list {
  min-height: inherit;
  height: inherit;
}

.feedback-analysis .feedback-analysis-chart {
  height: -webkit-fill-available;
}
.feedback-analysis ul {
  margin-top: 15px;
  margin-bottom: 0;
}
.feedback-analysis ul li {
  padding: 0px 15px 0 15px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .feedback-analysis ul li {
    font-size: 26px;
  }
}
@media (min-width: 992px) {
  .feedback-analysis ul li {
    font-size: 24px;
  }
}
.feedback-analysis ul li:first-child {
  color: #333;
}
.feedback-analysis ul li:nth-child(2) {
  color: rgb(75, 174, 79);
}
.feedback-analysis ul li:nth-child(3) {
  color: rgb(255, 64, 64);
}
.feedback-analysis ul li:nth-child(4) {
  color: rgb(255, 160, 55);
}
.feedback-analysis ul li:nth-child(5) {
  color: rgb(95, 124, 138);
}
.feedback-analysis ul li div {
  display: inline;
  font-size: 16px;
  margin-left: 3px;
}
.feedback-analysis ul li div i {
  font-size: 16px;
  margin-right: 3px;
}
.feedback-analysis ul li span {
  font-size: 12px;
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.charts .mini-card, .insights .mini-card {
  min-height: 170px;
  max-height: 170px;
  position: relative;
  overflow: visible;
}
.charts .mini-card .panel, .insights .mini-card .panel {
  border: none;
}
.charts .mini-card .panel-heading, .insights .mini-card .panel-heading {
  background-color: transparent;
}
.charts .mini-card .panel-title, .insights .mini-card .panel-title {
  font-size: 15px;
}
.charts .mini-card .panel-title span, .insights .mini-card .panel-title span {
  float: right;
}
.charts .mini-card .panel-body, .insights .mini-card .panel-body {
  height: auto;
  min-height: 0;
}
.charts .mini-card .panel-body .counts, .insights .mini-card .panel-body .counts {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  padding: 10px 0 !important;
  font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
  color: #707070;
}
@media (max-width: 1500px) {
  .charts .mini-card .panel-body .counts, .insights .mini-card .panel-body .counts {
    font-size: 40px;
  }
}
.charts .mini-card .data_type-dropdown, .insights .mini-card .data_type-dropdown {
  position: absolute;
  right: 1em;
  bottom: 1em;
  width: 35px;
  height: 35px;
  z-index: 3;
}
.charts .mini-card .data_type-dropdown .custom_dropdown, .insights .mini-card .data_type-dropdown .custom_dropdown {
  width: 100%;
  min-width: 100%;
  padding: 0.78571429em;
  height: 100%;
  min-height: 100%;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #ffffff;
}
.charts .mini-card .data_type-dropdown .custom_dropdown .fa, .insights .mini-card .data_type-dropdown .custom_dropdown .fa {
  display: none;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-wrapper, .insights .mini-card .data_type-dropdown .custom_dropdown-wrapper {
  width: 100%;
  height: 100%;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-text, .insights .mini-card .data_type-dropdown .custom_dropdown-text {
  height: 1em;
  max-width: 1ch;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
  margin: 0 auto;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-menu, .insights .mini-card .data_type-dropdown .custom_dropdown-menu {
  left: 0;
  right: auto;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-menu::before, .insights .mini-card .data_type-dropdown .custom_dropdown-menu::before {
  left: 9px;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-menu-list, .insights .mini-card .data_type-dropdown .custom_dropdown-menu-list {
  padding: 0;
}
.charts .mini-card .data_type-dropdown .custom_dropdown-item, .insights .mini-card .data_type-dropdown .custom_dropdown-item {
  padding: 10px;
  font-size: 0.9em;
  border: none;
  color: #707070;
}
.charts .segment-1, .insights .segment-1 {
  color: rgb(255, 64, 64);
}
.charts .segment-2, .insights .segment-2 {
  color: rgb(255, 160, 55);
}
.charts .segment-3, .insights .segment-3 {
  color: rgb(75, 174, 79);
}
.charts .segment-1, .insights .segment-1,
.charts .segment-2,
.insights .segment-2,
.charts .segment-3,
.insights .segment-3 {
  margin: -3px 30px 0 !important;
}
@media (max-width: 1500px) {
  .charts .segment-1, .insights .segment-1,
  .charts .segment-2,
  .insights .segment-2,
  .charts .segment-3,
  .insights .segment-3 {
    margin: -3px 10px 0 !important;
  }
}
.charts .segment-1 span, .insights .segment-1 span,
.charts .segment-2 span,
.insights .segment-2 span,
.charts .segment-3 span,
.insights .segment-3 span {
  display: block;
  margin: 7px 0 0;
}
.charts .recovered, .insights .recovered {
  background-color: rgb(32, 149, 242);
  color: white;
}
.charts .recovered .panel-heading, .insights .recovered .panel-heading {
  color: white;
  text-align: center;
}
.charts .unrecoverable, .insights .unrecoverable {
  background-color: rgb(95, 124, 138);
  color: white;
}
.charts .unrecoverable .panel-heading, .insights .unrecoverable .panel-heading {
  color: white;
  text-align: center;
}

.resp-counts {
  font-size: 18px;
  font-weight: 700;
  padding: 0px 5px;
}
@media (max-width: 1500px) {
  .resp-counts {
    font-size: 15px;
  }
}
.resp-counts-label {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
}

.data-health-container .panel-body {
  display: flex;
  justify-content: center;
}

.t-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.t-wrap .t-holder {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.remove-icon {
  position: relative;
  align-self: center;
  z-index: 2;
  top: -45px;
  margin: auto;
  right: -50px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 100%;
  background: #f4f4f2;
  text-align: center;
}
.remove-icon:hover {
  cursor: pointer;
  background: rgb(191, 10, 48);
  color: #fff;
}

/* User Profile Page styling */
.userProfileWrapper {
  /* Profile User Avatar*/
  /* Profile Edit bar */
  /* User Profile Form */
}
.userProfileWrapper.t-wrap {
  margin: 15px 0 0;
  height: calc(100% - 15px);
  background: #fff;
}
.userProfileWrapper .avatar-holder {
  margin: 0 0 50px;
}
.userProfileWrapper .avatar-holder .avatar {
  position: relative;
  display: block;
  width: 150px;
  height: 150px;
  margin: 0 auto 15px;
  border-radius: 100%;
}
.userProfileWrapper .avatar-holder .avatar .edit-icon {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: -5px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
  background: #f4f4f2;
  padding: 9px 12px;
  text-align: center;
}
.userProfileWrapper .avatar-holder .avatar:hover .edit-icon {
  cursor: pointer;
  background: rgb(45, 158, 45);
  color: #fff;
}
.userProfileWrapper .avatar-holder input[type=file] {
  display: none;
}
.userProfileWrapper .avatar-holder .form-label {
  padding-top: 7px;
}
.userProfileWrapper .edit-bar {
  position: relative;
  border-bottom: 1px solid #e8e8e4;
  margin: 0 0 20px;
  padding: 0 50px 0 0;
}
.userProfileWrapper .edit-bar .user-info {
  text-transform: capitalize;
  margin: 0 0 3px;
}
.userProfileWrapper .edit-bar .user-info p {
  display: inline-block;
  margin-bottom: 0;
}
.userProfileWrapper .edit-bar .user-info p .name {
  display: inline-block;
  margin: 0 0 0 5px;
  font-size: 20px;
  font-weight: bold;
}
.userProfileWrapper .edit-bar .user-info p .role {
  display: inline-block;
  color: gray;
  margin-left: 5px;
}
.userProfileWrapper .edit-bar .user-info i {
  font-size: 20px;
}
.userProfileWrapper .edit-bar .edit-icon {
  display: none;
}
.userProfileWrapper .form-wrap {
  padding: 20px 0;
}
.userProfileWrapper .main-holder {
  max-width: 700px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
}
.userProfileWrapper .form-group .title {
  margin: 10px 0 0;
  display: block;
  text-align: right;
}
.userProfileWrapper .form-group .alert {
  padding: 10px 0;
  margin-bottom: 0;
}
.userProfileWrapper .form-group .alert-danger {
  background: none;
  border: none;
}
.userProfileWrapper .btn-group .btn {
  margin: 0;
}

.new-user-heading {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  color: #15305b;
  font-weight: bold;
}

.new-user-form-body {
  margin-left: -10%;
}
.new-user-form-body .row {
  margin-bottom: 10px;
}
.new-user-form-body .form-control {
  background: #efefef;
  margin-bottom: 10px;
}
.new-user-form-body .save-btn {
  margin-top: 30px;
  padding: 5px 50px;
  border-radius: 20px;
  background: #15305b;
  color: white;
  border: none;
  margin-left: 30%;
}

.new-user-container {
  margin-top: 55px;
}

.department-select-popup {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.department-selection-title {
  text-align: center;
  margin-top: 17px;
  color: #15305b;
  font-family: inherit;
}

.department-selection-close {
  border-radius: 25px;
  display: block;
  width: 95px;
  margin: 10px auto 25px auto;
  background: #15305b;
  color: yellow;
  font-size: medium;
}

.department-name {
  border-radius: 25px;
  border: 2px solid #15305b;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.department-names {
  margin-left: 10%;
  font-size: unset;
  text-align: center;
  color: #15305b;
}

.department-name-select {
  background: #15305b;
  color: white;
  cursor: pointer;
}

.secondary-topbar {
  margin-top: 15px;
}
.secondary-topbar .row {
  margin-left: 0;
  margin-right: 0;
}
.secondary-topbar .user {
  display: block;
  position: relative;
}
@media (min-width: 768px) {
  .secondary-topbar .user {
    display: inline-block;
  }
}
.secondary-topbar .user .download-icon {
  line-height: 47px;
  border-left: none;
  padding: 12px !important;
  cursor: pointer;
}
.secondary-topbar .user .download-icon .download-icon-img {
  height: 34px;
}
.secondary-topbar .user .downloading-file {
  line-height: 47px;
  cursor: not-allowed;
}
.secondary-topbar .user .downloading-file .download-icon-img {
  height: 34px;
  margin-right: 9px;
}
.secondary-topbar .user .downloading-file .fa {
  color: black;
}
.secondary-topbar .user .dropdown .btn-default {
  background: transparent;
}
.secondary-topbar .user:last-child {
  padding-right: 0;
}
.secondary-topbar .user .dropdown-toggle {
  padding: 0;
  border: 0;
}
.secondary-topbar .user .dropdown-toggle:hover, .secondary-topbar .user .dropdown-toggle:focus, .secondary-topbar .user .dropdown-toggle:active {
  box-shadow: none;
  background: transparent;
}
.secondary-topbar .user .dropdown-menu {
  right: 0;
  left: auto;
}
.secondary-topbar .user .dropdown-menu > li > a {
  padding: 15px;
}
.secondary-topbar .user .dropdown-menu > li > a i {
  min-width: 15px;
}
.secondary-topbar .user .dropdown-menu > li.divider {
  margin: 0;
}
.secondary-topbar ul {
  list-style: none;
  padding-left: 0;
}
.secondary-topbar ul li.applied-filters {
  background: dodgerblue;
  color: white;
  padding: 3px 10px;
  font-weight: bold;
  margin: 5px 5px 0 0;
  display: inline-block;
}
.secondary-topbar .options {
  margin-top: 5px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.secondary-topbar .options .option {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  min-height: 3em;
  min-width: 3em;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  background-color: var(--filters-color);
  color: #fff;
}
.secondary-topbar .options .option img,
.secondary-topbar .options .option svg {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}
.secondary-topbar .options .option .fa {
  font-size: 1.3em;
  border-radius: 2px;
  line-height: 2.2em;
}
.secondary-topbar .options .option.flash {
  box-shadow: 0px 0px 6px 3px var(--secondary-color);
  animation: flash 1s linear 0s 10;
}
.secondary-topbar .options .option-group {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.secondary-topbar .options .option-group .option {
  margin: 0;
  position: relative;
}
.secondary-topbar .options .option-group > div {
  position: relative;
}
.secondary-topbar .options .option-group div:not(:first-child) .option::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 55%;
  top: 0;
  margin: auto;
  left: -1px;
  bottom: 0;
  border-left: 1px solid #fff;
}
.secondary-topbar .options .selected-range {
  line-height: 2em;
  cursor: pointer;
  min-width: 3em;
  margin: 0 0 0 5px;
  display: flex;
  justify-content: space-evenly;
}
.secondary-topbar .options .selected-range i {
  display: none;
}
.secondary-topbar .options .selected-range > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.secondary-topbar .options .selected-range span {
  white-space: nowrap;
  position: relative;
  width: auto;
  display: block;
  padding: 0 1em;
  flex: 1;
}
.secondary-topbar .options .selected-range span:not(:first-child)::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 70%;
  top: 0;
  margin: auto;
  left: -1px;
  bottom: 0;
  border-left: 1px solid #fff;
}
.secondary-topbar .filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-topbar .filters-wrapper .btn {
  margin: 0 8px;
  border-radius: 0;
}
.secondary-topbar .filters-wrapper .btn svg {
  color: blue;
}
.secondary-topbar .filters-wrapper .btn svg rect {
  color: currentColor;
}

div#nps-customer-options-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 0;
}
div#nps-customer-options-list .popover-content {
  padding: 0;
  border-radius: 0;
}
div#nps-customer-options-list .popover-content > div {
  padding-bottom: 22px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}
div#nps-customer-options-list .popover-content > div div.heading {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
}
div#nps-customer-options-list li {
  color: #585858;
  list-style: none;
  cursor: pointer;
}
div#nps-customer-options-list li .option-entry {
  width: 180px;
  padding: 2px 15px;
}
div#nps-customer-options-list div.heading {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

div#survey-types-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 5px;
}
div#survey-types-list .popover-content {
  padding: 0;
  border-radius: 0;
}
div#survey-types-list .popover-content > div:last-child > li > div.option-entry {
  border-bottom: none;
}
div#survey-types-list .popover-content .no-survey-types {
  padding: 10px;
}
div#survey-types-list .reset-filter {
  margin-right: 10px;
  margin-top: 5px;
  padding: 0;
  float: right;
}
div#survey-types-list li {
  color: #585858;
  list-style: none;
  cursor: pointer;
}
div#survey-types-list li .option-entry {
  width: auto;
  min-width: 200px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
div#survey-types-list li .option-entry .selected-icon {
  float: right;
}
div#survey-types-list div.heading {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.customer-type-list {
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.09);
  padding: 0;
}
.customer-type-list .popover-content {
  padding: 0;
  border-radius: 0;
}
.customer-type-list .popover-content > div {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}
.customer-type-list li {
  color: #585858;
  list-style: none;
  cursor: pointer;
}
.customer-type-list li .option-entry {
  width: 180px;
  padding: 2px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-message {
  text-align: left !important;
}
.alert-message a {
  text-decoration: none;
  display: table;
}
.alert-message .icon {
  background: var(--primary-color);
  color: white;
  display: table-cell;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  text-align: center;
  padding: 11px 0;
}
.alert-message .text {
  color: var(--primary-color);
  background: white;
  padding: 11px;
  border-left: 1px solid white;
  display: inline-block;
  max-width: 370px;
}
.alert-message .text:hover {
  background: var(--primary-color);
  color: white;
}

.footer {
  position: absolute;
  bottom: -35px;
  left: 0;
  right: 0;
}
.footer a {
  font-size: 14px;
}
.footer .poweredBy {
  text-align: end;
  color: black;
  display: none;
}
@media (min-width: 992px) {
  .footer .poweredBy {
    display: block;
  }
}
.footer .sm-logo {
  display: block;
  margin: 0 auto;
}
.footer .col-sm-6 {
  padding-left: 0px;
}

.feedback-groups-list-table td:last-child button, .users-list-table td:last-child button {
  width: 100px;
}

.levels-list-table td:last-child {
  width: 30%;
}
.levels-list-table td:last-child button {
  width: 80px;
}

.table-section {
  padding: 70px 100px 20px;
}

.add-btn {
  margin: 20px 5px 20px 5px;
  float: right;
  position: relative;
  right: 95px;
}

.feedback-group-form,
.level-form,
.user-form {
  background-color: #ffffff;
  margin: 30px auto;
  padding: 50px;
  width: 60%;
  border-radius: 5px;
}
.feedback-group-form .form-group,
.level-form .form-group,
.user-form .form-group {
  margin-top: 15px;
  padding: 5px;
}
.feedback-group-form .panel,
.level-form .panel,
.user-form .panel {
  border-radius: 5px;
  border: solid 1px #ccc;
}

.feedback-group-form-btn,
.level-form-btn,
.add-user-btn {
  float: right;
}

.add-analyzer-btn {
  float: right;
}

.remove-analyzer-btn {
  margin-top: 45px;
  position: absolute;
  right: 0;
}
.remove-analyzer-btn.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.questionnaire-division-container {
  margin-top: 50px;
}
.questionnaire-division-container .questionnaire-stats {
  margin-top: 40px;
}
.questionnaire-division-container .division-details .Collapsible {
  width: 100%;
}
.questionnaire-division-container .division-details .questionnaire-edit {
  margin-top: 10px;
}
.questionnaire-division-container .division-details .percentage-input input {
  width: 100px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}
.questionnaire-division-container .division-details .percentage-input input[type=number]:disabled {
  background: lightgrey;
  cursor: not-allowed;
}
.questionnaire-division-container .roles-container {
  margin-left: 10px;
}
.questionnaire-division-container .roles-container:last-child {
  padding-bottom: 20px;
}
.questionnaire-division-container .roles-container .questionnaire-table {
  margin-left: 30px;
}
.questionnaire-division-container .roles-container .questionnaire-table th {
  padding-bottom: 10px;
}
.questionnaire-division-container .roles-container .questionnaire-table tbody > tr > td {
  border-top: 1px solid #ddd !important;
}
.questionnaire-division-container .roles-container .questionnaire-table th:last-child {
  text-align: center;
}
.questionnaire-division-container .roles-container .questionnaire-table tr:last-child {
  font-weight: bold;
}
.questionnaire-division-container .roles-container .questionnaire-table td {
  padding: 0;
  margin: auto;
}
.questionnaire-division-container .roles-container .questionnaire-table td:last-child {
  text-align: center;
}
.questionnaire-division-container .roles-container .leaf-node {
  height: 50px;
}
.questionnaire-division-container .roles-container .questionnaire-update {
  margin: 10px;
  float: right;
}

.color-picker {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.color-picker > .selected-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.pickers-container {
  padding: 5px;
}

.picker-container {
  position: relative;
}
.picker-container .picker-popover {
  position: absolute;
  z-index: 999;
}

.survey-wizard-container {
  width: 70%;
  margin: 20px auto;
}
.survey-wizard-container .add-remove-question-container {
  margin-top: 15px;
}
.survey-wizard-container .add-remove-question-container .remove-question {
  width: 150px;
}
.survey-wizard-container .add-remove-question-container .add-question {
  width: 150px;
}

.add-survey-detail-container {
  background-color: #ffffff;
  padding: 25px 10%;
  border-radius: 5px;
}
.add-survey-detail-container .form-group {
  margin-bottom: 15px;
}
.add-survey-detail-container textarea {
  resize: none;
  height: 70px;
}
.add-survey-detail-container .survey-configs-container {
  margin-top: 30px;
}

.question-form-container, .question-form-container .option-form-container, .question-form-container .option-form-container .sub-option-form-container {
  position: relative;
  background-color: #ffffff;
  padding: 25px 10%;
  border-radius: 5px;
  margin-top: 30px;
}
.question-form-container .option-form-container, .question-form-container .option-form-container .sub-option-form-container {
  padding: 25px 3%;
  border: solid 1px #d3d3d3;
  box-shadow: 3px 3px 10px grey;
  margin-bottom: 10px;
}
.question-form-container .option-form-container .sub-option-form-container {
  padding: 25px 3%;
  border: solid 1px #d3d3d3;
  box-shadow: 3px 3px 10px grey;
  margin-bottom: 10px;
}
.question-form-container .maximum-selected-options input {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 5px;
}
.question-form-container .maximum-selected-options .label-text {
  font-weight: 700;
  margin-bottom: 5px;
}

.delete-container {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.container-heading {
  position: relative;
  bottom: 20px;
  right: 40px;
  color: gray;
}

.opt-container-heading {
  position: relative;
  bottom: 20px;
  color: gray;
}

.add-survey-btn {
  position: fixed;
  top: 90px;
  right: 2%;
}
.add-survey-btn .sidebar-btn {
  width: 150px;
}

.survey-list-tbl {
  margin-top: 25px;
}
.survey-list-tbl td:last-child button {
  width: 100px;
}

.add-new-survey {
  margin-top: 20px;
}

.toggle-active-status {
  position: absolute;
  top: 13px;
  right: 55px;
}

.delete-btn {
  display: block;
  width: max-content;
  height: max-content;
  position: relative;
  padding: 10px;
  border: 1px solid #555;
  margin-bottom: 15px;
}
.delete-btn:hover {
  border: 1px solid black;
}
.delete-btn i.fa.fa-times {
  top: -7px;
  right: -7px;
  left: unset;
  background: white;
  border-radius: 50px;
  color: #555;
  cursor: pointer;
}
.delete-btn i.fa.fa-times:hover {
  color: black;
}
.delete-btn i + * {
  margin: 0;
}

.parent-row {
  display: flex;
}
.parent-row .is-active {
  margin-left: 30px;
}

.division-container {
  margin-top: 30px;
}
.division-container .add-division-btn {
  margin-bottom: 20px;
}

.Collapsible {
  padding-left: 50px;
  padding-right: 50px;
  border: solid 1px lightgray;
  width: 90%;
}

.children-row:last-child {
  padding-bottom: 20px;
}

.leaf {
  padding-left: 50px;
  border: solid 1px lightgray;
  height: 40px;
  padding-top: 10px;
  width: 90%;
}

.leaf-container {
  display: flex;
}

.edit-division {
  font-size: 15px;
  margin-left: 30px;
  cursor: pointer;
}

.add-edit-division-form-container {
  background-color: #ffffff;
  margin: 30px auto;
  padding: 50px;
  width: 60%;
  border-radius: 5px;
}

.division-row {
  width: 100%;
}
.division-row > div {
  display: inline-block;
}

.Collapsible {
  background-color: white;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  padding: 10px;
}

.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.pivotable-container {
  padding: 5% 0;
  overflow: scroll;
}

.question table {
  height: 310px;
  display: -moz-groupbox;
  empty-cells: show;
}
.question table thead {
  display: inline-table;
  width: 100%;
}
.question table tbody {
  display: block;
  width: 100%;
  overflow-y: scroll;
  height: 270px;
}
.question table tr {
  display: table;
  width: 100%;
}
.question table tr td:first-child, .question table tr th:first-child {
  width: 75%;
}
.question table tr td, .question table tr td {
  width: 25%;
}

.setup-profile {
  min-height: calc(100vh - 140px);
  padding: 0 100px;
}
@media (max-width: 769px) {
  .setup-profile {
    padding: 0;
  }
}
.setup-profile .logo {
  max-height: 60px;
  margin: 15px 0;
}
@media (max-width: 992px) {
  .setup-profile .logo {
    display: block;
    margin: 15px auto;
    max-height: 50px;
  }
}
.setup-profile .panel-body {
  padding: 15px 15px 50px;
}
.setup-profile .panel-body h3 {
  text-align: center;
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .setup-profile .fields-wrapper {
    display: flex;
  }
}
.setup-profile .error {
  color: #d50d1e;
  font-weight: bold;
}
.setup-profile .error a {
  color: #d50d1e;
}
.setup-profile .error a:focus, .setup-profile .error a:active, .setup-profile .error a:hover, .setup-profile .error a:visited {
  color: #d50d1e;
}
.setup-profile .error .not-allowed {
  margin-right: 5px;
  font-size: 17px;
}
.setup-profile .has-error .form-control {
  color: #d50d1e;
  border-color: #d50d1e;
}
.setup-profile .form-group {
  margin-bottom: 20px;
}
.setup-profile .form-control {
  height: 40px;
}
.setup-profile button {
  width: 100%;
  background: var(--primary-color);
  float: none;
}
.setup-profile .image-field {
  display: none;
}
.setup-profile .button-holder {
  text-align: center;
}
.setup-profile .button-holder .image-select-label {
  background: var(--primary-color);
  color: var(--text-color);
}
.setup-profile .button-holder .fa {
  position: static;
  margin: 0 5px 0 0;
}
.setup-profile .avatar-image {
  display: block;
  width: 150px;
  height: 150px;
  margin: 20px auto 10px;
  border: 2px solid #eee;
  border-radius: 50%;
}

::selection {
  background-color: var(--secondary-color-light);
}

::-moz-selection {
  background-color: var(--secondary-color-light);
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  background: #f5f5f5;
  color: #707070;
  overscroll-behavior-y: none;
  overflow-x: hidden;
  line-height: 1.42857143;
  font-size: 14px;
}

#content-wrapper {
  padding: 0 30px;
  background: #f5f5f5;
  position: relative;
  min-height: calc(100vh - 135px);
}
#content-wrapper > span {
  display: block;
}

footer {
  text-align: center;
  background: #f4f4f2;
  padding: 5px;
  width: 100%;
}
footer a {
  color: rgb(150.5, 150.5, 131.5);
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
footer a:hover {
  text-decoration: none;
  color: rgb(97.5, 97.5, 82.5);
}

.tooltipRel {
  position: relative;
}
.tooltipRel:hover .tooltip-text-wrapper {
  visibility: visible;
  opacity: 1;
}

.custom-scrollbar .custom-scroll-handle {
  right: 0;
}
.custom-scrollbar .inner-handle {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.sk-spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  opacity: 0.9;
  transform: translate(-50%, -50%);
}
.sk-spinner > div {
  background-color: #d50d1e;
}
.sk-spinner div {
  width: 12px;
  height: 12px;
  animation: sk-cubemove 2s infinite ease-in-out;
}
.sk-spinner div:first-child {
  background-color: rgb(255, 160, 55);
}

.notFound {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-data-found {
  font-weight: bolder;
  color: currentColor;
  font-size: 19px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-family: "SegoeUI-Light", Arial, Helvetica, sans-serif;
}

.organization-not-found {
  background: white;
  border: 1px solid #666;
  border-radius: 50%;
  box-shadow: 5px 30px 30px 30;
  font-size: 25px;
  text-align: center;
  width: 700px;
  position: absolute;
  left: calc(50% - 350px);
  top: calc(50% - 350px);
  padding: 350px 0px;
  box-shadow: 0px 0px 0px 250px rgb(255, 64, 64), 0px 0px 0px 500px rgb(255, 84.4, 84.4), 0px 0px 0px 750px rgb(255, 104.8, 104.8), 0px 0px 0px 1000px rgb(255, 125.2, 125.2);
}
.organization-not-found span {
  display: block;
  font-size: 13px;
  color: rgb(54, 63, 59);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px rgb(255, 249.6, 204) inset !important;
}

.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.tooltip .tooltip-inner {
  text-align: left;
  max-width: 300px;
}

.help {
  position: fixed;
  background: rgb(0, 90, 171);
  z-index: 999;
  bottom: 55px;
  right: -22px;
  width: 70px;
  text-align: center;
  border: 1px solid white;
  transform: rotate(-90deg);
}
.help a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 4px 10px;
  color: white;
  text-decoration: none;
  outline: none !important;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  color: rgb(160, 160, 160);
}

.btn-primary {
  border-color: transparent;
  background: var(--primary-color);
  color: var(--text-color);
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:visited {
  background: var(--primary-color);
  color: var(--text-color);
}
.btn-primary.dropdown-toggle {
  background: transparent;
}
.btn-primary.dropdown-toggle:after {
  display: none;
  content: "";
}

.priority-list td {
  padding: 7px !important;
}

.parent-option {
  font-size: 12, initial;
  font-weight: bold;
  color: rgb(160, 160, 160);
}

.priority-value {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.priority-value span {
  font-size: 14px;
  color: #777;
  font-weight: normal;
}
.priority-value.benchmarks {
  text-align: left;
}

#priority-list-tabs {
  height: inherit;
}
#priority-list-tabs .tab-content,
#priority-list-tabs #priority-list-tabs-pane-overall,
#priority-list-tabs .error-message,
#priority-list-tabs #priority-list-tabs-pane-inner,
#priority-list-tabs #priority-list-tabs-pane-outer {
  height: inherit;
}

.thumbnail {
  max-height: 100px;
  max-width: 100px;
}

.info-icon {
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
}

.plotlyjsicon {
  display: none;
}

.notification {
  cursor: pointer;
  color: rgb(0, 90, 171);
}
.notification.disabled {
  cursor: not-allowed;
  color: rgb(157, 157, 157);
}

.notification-all {
  color: #f4f4f2;
  background: rgb(0, 90, 171);
}

.alert-msg {
  margin: 5px 0 !important;
}

.highlight-text {
  background-color: yellow;
}

.input-label {
  margin: 0 5px 0 0;
}

.compare-toggle {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  border-radius: 2px;
}

.react-toggle--checked .react-toggle-track {
  background: rgb(75, 174, 79);
}

.react-toggle-thumb {
  background: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  background-color: #ccc;
}

.clear-col-padding {
  padding-left: 0;
  padding-right: 0;
}

.clear-col-padding-right {
  padding-right: 0;
}

.clear-col-padding-left {
  padding-right: 0;
}

.ranges {
  overflow-y: scroll;
  max-height: 290px;
}

.timeseries .tab-content,
.timeseries canvas {
  height: 268px !important;
  display: flex;
}

.timeseries .tab-content .tab-pane {
  width: 100%;
}

.timeseries .nav.nav-tabs {
  margin-top: -20px;
}

.timeseries .nav-item.nav-link {
  border: none;
  border-bottom: solid 2px transparent;
  margin-right: 5px;
}

.timeseries .nav-item.nav-link.active {
  border-bottom: solid 2px var(--primary-color);
}

.timeseries .nav-item.nav-link.active {
  border-bottom-color: var(--primary-color);
}

.timeseries .nav-item.nav-link:hover {
  border-bottom-color: var(--primary-color);
}

.timeseries .tab-content {
  margin-top: 10px;
}

.primary-button {
  padding: 10px;
  color: white;
  background: rgb(0, 90, 171);
  text-align: center;
  cursor: pointer;
  float: right;
}
@media (max-width: 769px) {
  .primary-button {
    float: left;
  }
}

.list {
  list-style: none;
  padding: 0;
}
.list li:hover {
  background: lightgray;
}

.tooltip-table {
  background: black;
  color: white;
}

.dropdown-wrapper {
  position: relative;
}

.css-2b097c-container {
  min-height: 46px;
}
.css-2b097c-container .css-yk16xz-control {
  min-height: 46px;
  border-color: #e9e9e9;
}
.css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer {
  min-height: 46px;
  padding: 2px 24px;
}
.css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer .css-1rhbuit-multiValue {
  padding: 2px 8px;
  border-radius: 14px;
  background-color: #e9e9e9;
}
.css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer:invalid {
  border: 1px solid #f33;
}
.css-2b097c-container .css-yk16xz-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-2b097c-container .css-1pahdxg-control {
  min-height: 46px;
}

.select__control--is-focused {
  padding: 0 16px;
}
.select__control--is-focused .select__multi-value {
  padding: 2px 8px;
  border-radius: 14px;
  background-color: #e9e9e9;
}

.select__menu {
  z-index: 10 !important;
}

.css-1gtu0rj-indicatorContainer {
  display: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.dateRangePicker .rdrDateRangeWrapper {
  width: 100%;
}
.dateRangePicker .rdrDateDisplayWrapper {
  background-color: transparent;
  display: none;
}
.dateRangePicker .rdrDateDisplayItemActive {
  border-color: var(--primary-color);
}
.dateRangePicker .rdrNextPrevButton {
  background-color: #eee;
}
.dateRangePicker .rdrNextPrevButton:hover {
  background-color: #ccc;
}
.dateRangePicker .rdrNextPrevButton.rdrPprevButton i {
  border-color: transparent var(--primary-color) transparent transparent;
}
.dateRangePicker .rdrNextPrevButton.rdrNextButton i {
  border-color: transparent transparent transparent var(--primary-color);
}
.dateRangePicker .rdrSelected,
.dateRangePicker .rdrInRange,
.dateRangePicker .rdrStartEdge,
.dateRangePicker .rdrEndEdge {
  background: var(--primary-color);
  top: 3px;
  left: 0;
  right: 0;
  bottom: 3px;
}
.dateRangePicker .rdrStartEdge {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.dateRangePicker .rdrEndEdge {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.dateRangePicker .rdrDayEndOfMonth .rdrInRange,
.dateRangePicker .rdrDayEndOfMonth .rdrStartEdge,
.dateRangePicker .rdrDayEndOfWeek .rdrInRange,
.dateRangePicker .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.dateRangePicker .rdrDayStartOfMonth .rdrInRange,
.dateRangePicker .rdrDayStartOfMonth .rdrEndEdge,
.dateRangePicker .rdrDayStartOfWeek .rdrInRange,
.dateRangePicker .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.dateRangePicker .rdrDay,
.dateRangePicker .rdrDayStartPreview,
.dateRangePicker .rdrDayEndPreview,
.dateRangePicker .rdrDayInPreview {
  color: var(--primary-color) !important;
}
.dateRangePicker .rdrDayToday .rdrDayNumber span:after {
  background: var(--primary-color);
  bottom: 0;
  width: 10px;
}
.dateRangePicker .rdrMonth {
  width: 17rem;
}

.css-1rhbuit-multiValue {
  padding: 2px 8px;
  border-radius: 14px !important;
  background-color: #e9e9e9 !important;
}

.css-1q5dc95-control:hover, .css-1q5dc95-control:focus {
  border: 0 !important;
}

.react-horizontal-scrolling-menu--scroll-container {
  display: -webkit-inline-box !important;
  overflow-x: auto;
  width: 100%;
  height: auto;
}

