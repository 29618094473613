.charts,
.questionaire {
  margin-top: 20px;

  .panel {
    border-radius: 0;
    box-shadow: none;
    // box-shadow: 5px 5px 0 darken($light-gray, 5%);
    // border: 1px solid darken($light-gray, 5%);
    margin-bottom: 16px;
    padding: 20px 10px;
    height: calc(100% - 16px);
    .panel-heading {
      border-radius: 0;
      color: #707070;
      background-color: white;
      // border-bottom: 2px solid darken($light-gray, 5%);
      border: none;
      font-family: "SegoeUI", Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 0;
      padding: 0 0 15px;
      text-transform: capitalize;
      text-align: center;
      min-height: 40px;
    }
    .panel-title {
      font-size: 15px;

      & span {
        float: right;
      }
    }
    .panel-body {
      min-height: 310px;
      height: 15vw;
      padding: 0;
      border-left: none;

      div.row {
        height: 99%;
      }
    }
    .avg-rating {
      position: absolute;
      top: 20px;
      right: 55px;

      .star-icon {
        color: yellow;
      }

      .avg-rating-score {
        background-color: lightgrey;
        padding: 3px 10px 3px 10px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  #counter {
    text-align: center;
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    top: 50%;
    right: 0;
    text {
      color: #333;
      font-weight: bold;
      text-align: center;
      font-size: 100px;
      display: block;
      line-height: normal;
      padding: 2% 0;
      letter-spacing: -6px;
    }
    h5 {
      color: darken($light-gray, 50%);
      margin-bottom: 0;
      font-weight: normal;
      font-size: 16px;
      margin-top: 0;
      line-height: 22px;
      span {
        margin-right: 10px;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        &:before {
          margin-right: 4px;
          font-family: FontAwesome, sans-serif;
        }
        &[class*="down"] {
          color: $color1;
        }
        &[class*="up"] {
          color: $color3;
        }
      }
      &.red {
        strong {
          color: $color1;
        }
      }
      &.green {
        strong {
          color: $color3;
        }
      }
      strong {
        margin-left: 5px;
      }
    }
  }
  .custom-labels {
    margin: 0;
    padding: 0 0 10px 0;
    list-style: none;
    text-align: center;
    li {
      display: inline-block;
      margin-right: 8px;
      padding-left: 24px;
      position: relative;
      font-size: 12px;
      color: darken($light-gray, 60%);
      &:last-child {
        margin-right: 0;
      }
      &:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 1px;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background-color: $light-gray;
      }
      &:nth-child(1) {
        &:before {
          background-color: $color1;
        }
      }
      &:nth-child(2) {
        &:before {
          background-color: $color2;
        }
      }
      &:nth-child(3) {
        &:before {
          background-color: $color3;
        }
      }
    }
  }

  .feedback-stats-card {
    margin-bottom: 0;
    min-height: 1px !important;
    max-height: none !important;

    .panel-body {
      height: 5vw !important;
    }
    .panel-heading {
      padding: 0;
    }
  }
}

#chartjs-tooltip {
  padding: 15px;
  background: black;
  border-radius: 5px;
  opacity: 0.9;
}

table.chart-tooltip {
  background: black;
  color: white;
  opacity: 0.9;

  & thead {
    border-bottom: 2px solid white;
    text-align: center;
  }

  & td,
  & th {
    padding: 3px;
  }
}

.questionaire {
  .panel {
    .panel-heading {
      height: 48px;
      padding: 0;
    }
  }
}

div[id*="custom-tooltip"] {
  padding: 5px;
  border-radius: 5px;
  opacity: 0.8;

  .tooltip-table {
    color: whitesmoke;
    .header {
      text-align: left;
      border-bottom: 2px solid whitesmoke;
    }

    th {
      border-bottom: 2px solid whitesmoke;
    }

    td,
    th {
      padding: 3px 10px;
      text-align: center;
    }

    tr th:first-child,
    tr td:first-child {
      text-align: left;
    }
  }
}

#kpi-detail-tooltip {
  font-size: 12px;
  .table {
    color: whitesmoke;
  }
}
