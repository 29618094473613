.table {
  margin-bottom: 0;

  td,
  th {
    vertical-align: middle !important;
    word-wrap: break-word;
    border-top: none !important;
  }
}

.benchmarks,
.priority-list {
  min-height: inherit;
  height: inherit;
}

.feedback-analysis {
  .feedback-analysis-chart {
    height: -webkit-fill-available;
  }

  ul {
    margin-top: 15px;
    margin-bottom: 0;

    li {
      // font-size: 22px;
      padding: 0px 15px 0 15px;
      font-weight: 300;

      @media #{$phone} {
        font-size: 26px;
      }

      @media #{$min-tablet} {
        font-size: 24px;
      }

      &:first-child {
        color: #333;
      }

      &:nth-child(2) {
        color: $color3;
      }

      &:nth-child(3) {
        color: $color1;
      }

      &:nth-child(4) {
        color: $color2;
      }

      &:nth-child(5) {
        color: $color4;
      }

      div {
        display: inline;
        font-size: 16px;
        margin-left: 3px;

        i {
          font-size: 16px;
          margin-right: 3px;
        }
      }

      span {
        font-size: 12px;
        margin-right: 3px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.charts {
  .mini-card {
    min-height: 170px;
    max-height: 170px;
    position: relative;
    overflow: visible;

    .panel {
      border: none;
    }
    .panel-heading {
      background-color: transparent;
    }
    .panel-title {
      font-size: 15px;

      & span {
        float: right;
      }
    }
    .panel-body {
      height: auto;
      min-height: 0;
      .counts {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        font-weight: bold;
        padding: 10px 0 !important;
        font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
        color: #707070;

        @media #{$desktop} {
          font-size: 40px;
        }
      }
    }
    .data_type-dropdown {
      position: absolute;
      right: 1em;
      bottom: 1em;
      width: 35px;
      height: 35px;
      z-index: 3;

      .custom_dropdown {
        width: 100%;
        min-width: 100%;
        padding: 0.78571429em;
        height: 100%;
        min-height: 100%;
        background-color: transparent;
        border-radius: 0;
        border: none;
        color: #ffffff;

        .fa {
          display: none;
        }

        &-wrapper {
          width: 100%;
          height: 100%;
        }
        &-text {
          height: 1em;
          max-width: 1ch;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          word-break: break-all;
          overflow: hidden;
          margin: 0 auto;
        }
        &-menu {
          left: 0;
          right: auto;
        }

        &-menu::before {
          left: 9px;
        }

        &-menu-list {
          padding: 0;
        }
        &-item {
          padding: 10px;
          font-size: 0.9em;
          border: none;
          color: #707070;
        }
      }
    }
  }

  .segment-1 {
    color: $color1;
  }

  .segment-2 {
    color: $color2;
  }

  .segment-3 {
    color: $color3;
  }

  .segment-1,
  .segment-2,
  .segment-3 {
    margin: -3px 30px 0 !important;
    @media #{$desktop} {
      margin: -3px 10px 0 !important;
    }
    span {
      display: block;
      margin: 7px 0 0;
    }
  }

  .recovered {
    background-color: $color9;
    color: white;
    .panel-heading {
      color: white;
      text-align: center;
    }
  }

  .unrecoverable {
    background-color: $color4;
    color: white;
    .panel-heading {
      color: white;
      text-align: center;
    }
  }
}

.resp-counts {
  font-size: 18px;

  font-weight: 700;
  padding: 0px 5px;

  @media #{$desktop} {
    font-size: 15px;
  }

  &-label {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }
}

.data-health-container {
  .panel-body {
    display: flex;
    justify-content: center;
  }
}
