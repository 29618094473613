#header {
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translate3d(0, 0, 0) !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
    -o-transform: translate3d(0, 0, 0) !important;
  }

  &.hide {
    display: block !important;
  }

  &.responsive-header {
    position: sticky;
    background: var(--primary-color);
    width: 100%;
    display: none;
    margin-bottom: 15px;

    &.visible {
      display: block;
    }

    .logo-container {
      display: inline-block;
      line-height: 4.5;
    }

    .logo-wrapper {
      width: 170px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media #{$min-tablet} {
        &.setup-profile-panel-logo {
          position: static;
          margin-left: 90px;
          transform: unset;
        }
      }
    }

    .scroll-btn {
      display: inline-block;
      color: white;
      font-size: 20px;
      cursor: pointer;
      line-height: 3.5;

      &.right {
        float: right;
        padding-right: 10px;
      }

      &.left {
        float: left;
        padding-left: 10px;
      }
    }
  }

  @media #{$tablet} {
    &.responsive-header {
      display: block;
    }

    &.hide {
      display: none !important;
    }
  }

  .logo-container {
    height: 70px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    height: 70px;
    cursor: pointer;
  }

  #logo {
    margin: 2% 0;
    height: 70%;
  }

  .store-view-dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .custom_dropdown {
      border-radius: 25px;

      &-wrapper {
        margin: 0 !important;
      }

      &-menu {
        border: none;
        padding: 0;
        left: 0;
        right: auto;

        ::before {
          right: 20px;
          left: unset;
        }
      }

      &-menu-list {
        padding: 0;
        max-height: 550px;
        overflow: auto;
        min-width: max-content;
      }

      &-text {
        color: var(--primary-color);
        font-family: "SegoeUI", Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 1.1em;
        height: 1em;
        max-width: 18ch;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        overflow: hidden;
        // text-overflow: ellipsis;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          right: 0;
          width: 30px;
          height: 100%;
          top: 0;
          bottom: 0;
          background-image: linear-gradient(to right, transparent, white);
        }

        span {
          color: #b4b5b5;
          font-size: inherit;
          font-family: inherit;
          max-height: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &-item {
        color: #4d565c;
        font-family: "SegoeUI", Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 0.9em;
        position: relative;
        padding: 10px 13px 10px 30px;
        // float: left;
        width: 100%;
        word-break: keep-all;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        span {
          color: #b4b5b5;
          font-size: inherit;
          font-family: inherit;
          float: unset !important;
          text-align: right;
        }

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #b4b5b5;
          position: absolute;
          left: 10px;
          top: 0;
          bottom: 0;
          right: auto;
          margin: auto;
        }

        &.active::before {
          background-color: #89c120;
        }
        &.deactive::before {
          background-color: #e61f1f;
        }
        &.disable::before {
          background-color: #b4b5b5;
        }
        &.dormont::before {
          background-color: #ffa037;
        }
        &.all::before {
          background-color: transparent;
        }
      }
    }
  }

  .row {
    div.navigation-panel {
      min-height: 50px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: -webkit-fill-available;
      div.navigation-options {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 70px;
        .navigation-option {
          display: flex;
          justify-content: left;
          align-items: center;
          padding: 8px 0;
          font-size: 1.1em;
          font-family: "SegoeUI", Arial, Helvetica, sans-serif;
          color: inherit;
          margin-left: 3em;
          text-decoration: none;
          text-transform: capitalize;
          font-weight: lighter;
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .fa {
            margin-right: 5px;
            display: none;
          }

          &.active::after {
            content: "";
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 7px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: currentColor;
          }
        }

        .notification-button {
          margin-left: auto;
          font-size: 1.2rem;
          border: none;
          margin-right: 0;
          padding: 0;

          &.wrapper__button {
            color: #edbe23;
          }

          &.wrapper__button .counter {
            height: 20px;
            width: 20px;
            background-color: #d50d1e;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            position: absolute;
            top: 0px;
            bottom: 10px;
            margin: auto;
            right: -12px;
            border-radius: 50%;
            padding: 3px;
            text-align: center;
          }

          i {
            font-size: 20px;
          }

          .notifications.menu {
            position: absolute;
            top: calc(100% + 4px);
            left: auto;
            box-shadow: 0 0 15px 5px rgba(34, 36, 38, 0.15);
            width: 400px;
            background-color: #fff;
            z-index: 99;
            margin: 0 auto;
            cursor: auto;
            cursor: initial;
            right: -20px;

            &::before {
              content: " ";
              width: 0;
              height: 0;
              border-bottom: 15px solid #fff;
              border-top: 15px solid transparent;
              border-left: 15px solid transparent;
              border-right: 15px solid transparent;
              position: absolute;
              left: auto;
              right: 12px;
              top: -25px;
              z-index: -1;
            }
          }

          .notifications--button {
            align-content: end;
            background-color: #fff;
            box-shadow: none;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0;
            margin: 0;

            .see-all {
              border-radius: 0;
            }
          }

          .notifications--list {
            height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0;
            color: #707070;
          }

          .notifications--list .holder {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom-style: solid;
            border-bottom-color: #efefef;
            border-bottom-width: 1px;
            padding: 12px 8px;
          }
        }

        .user {
          display: flex;
          align-items: center;
          padding-left: 30px;
          position: relative;
          margin-left: 0;

          .download-icon {
            line-height: 47px;
            border-left: none;
            padding: 12px !important;
            cursor: pointer;
            .download-icon-img {
              height: 34px;
            }
          }
          .downloading-file {
            line-height: 47px;
            cursor: not-allowed;
            .download-icon-img {
              height: 34px;
              margin-right: 9px;
            }
            .fa {
              color: black;
            }
          }

          .dropdown {
            .btn-default {
              background: transparent;
            }
            &-header {
              font-family: "SegoeUI", Arial, Helvetica, sans-serif;
              font-size: 1.1em;
              padding: 1rem 1.5rem;

              .user-name span {
                color: #707070;
                font-size: 0.75em;
              }
            }
            &-menu a {
              margin: 0;
            }
          }

          &:last-child {
            padding-right: 0;
          }
          .dropdown-toggle {
            border-radius: 50%;
            background-color: var(--primary-color);
            padding: 0;
            border: 0;
            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
              background: transparent;
            }
          }
          .dropdown-menu {
            top: 100% !important;
            left: auto !important;
            margin: 10px 0 0 !important;
            right: 0 !important;
            bottom: auto !important;
            transform: translate3d(0, 0, 0) !important;
            padding: 10px 20px;
            border-radius: 0;
            border: none;
            -webkit-box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
            box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
            text-align: center;

            &::before {
              position: absolute;
              top: -9px;
              right: 12px;
              display: inline-block;
              border-right: 9px solid transparent;
              border-bottom: 9px solid white;
              border-left: 9px solid transparent;
              content: "";
            }

            a {
              font-size: 0.9em;
              text-transform: none;
              font-weight: normal;
              padding: 15px;
              color: #4d565c !important;
              i {
                min-width: 15px;
              }
            }

            a:hover,
            a:visited,
            a:active,
            a:focus {
              background-color: #f5f5f5;
            }

            &.divider {
              margin: 0;
            }

            .dropdown-header {
              white-space: normal;
            }
          }
          .dropdown-divider {
            margin: 0;
          }

          &-img {
            padding: 0;
          }
        }

        .organization {
          display: inline;
          padding-left: 30px;
          .organization-dropdown {
            color: white;
            font-size: 1.1em;
            font-weight: lighter;
            .icon {
              padding-right: 5px;
            }
          }
          .dropdown-toggle {
            padding: 0;
            border: 0;
            &:hover,
            &:focus,
            &:active {
              box-shadow: none;
              background: transparent;
            }
          }
          .dropdown-menu {
            right: 0;
            left: auto;
            a {
              font-size: 1em;
              text-transform: none;
              font-weight: normal;
              padding: 15px;
              color: black !important;
              i {
                min-width: 15px;
              }
            }
            &.divider {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.modal-header {
  border-bottom: none;

  .filter-header {
    text-align: center;
    font-size: medium;
    width: 100%;
  }
}

.modal-lg,
.modal-md,
.modal-sm {
  width: 60%;
  .modal-footer {
    border-top: none;
    .apply-button {
      background: forestgreen;
      border: none;
      border-radius: 0px;
      color: white;
    }
    .reset-button {
      background: gray;
      border: none;
      border-radius: 0px;
      color: white;
    }
  }
  .modal-body {
    padding-top: 0px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0px;
    .divisions-list {
      display: flex;

      .disabled {
        color: #999;
        cursor: not-allowed;

        ul li:hover {
          cursor: not-allowed !important;
          background: white !important;
        }
      }

      .division-list {
        text-align: center;
        border-right: 1px solid lightgray;
        @include with-count(1) {
          border-right: 0px;
        }
        padding-top: 15px;
        ul {
          list-style: none;
          text-align: justify;
          margin-top: 10px;
          max-height: 350px !important;
          overflow-y: auto;
          li {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
            padding-bottom: 2px;
            margin-left: -40px;
          }
          li.location-select {
            background: #bbb;
          }
          li:hover {
            cursor: context-menu;
            background: #ddd;
          }
        }
      }
    }
    .division-titles {
      text-align: center;
      width: 100%;
      font-weight: 500;

      .division-title {
        border-right: 1px solid lightgray;
      }
    }
    .selected-filters {
      padding: 5px;
      border-bottom: 1px solid lightgrey;
      padding-bottom: 11px;
      padding-top: 1px;
      min-height: 35px !important;
      ul {
        li.filter {
          color: white;
          background: dodgerblue;
          padding: 4px;
          margin: 3px;
          display: inline-block;
          .glyphicon {
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
