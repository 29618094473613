@font-face {
  font-family: "SegoeUI";
  src: url("../fonts/SegoeUI/SegoeUI.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI.svg#SegoeUI") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI-Light";
  src: url("../fonts/SegoeUI/SegoeUI-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI-Light.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI-Light.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI-Light.svg#SegoeUI-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI-Medium";
  src: url("../fonts/SegoeUI/SegoeUI-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI-Medium.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI-Medium.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI-Medium.svg#SegoeUI-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI-Bold";
  src: url("../fonts/SegoeUI/SegoeUI-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SegoeUI/SegoeUI-Bold.woff") format("woff"),
    url("../fonts/SegoeUI/SegoeUI-Bold.ttf") format("truetype"),
    url("../fonts/SegoeUI/SegoeUI-Bold.svg#SegoeUI-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}
