$border-color: #e8e8e4;

.t-wrap {
  display: table;
  width: 100%;
  height: 100%;
  .t-holder {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
}
.remove-icon {
  position: relative;
  align-self: center;
  z-index: 2;
  top: -45px;
  margin: auto;
  right: -50px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 100%;
  background: #f4f4f2;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: rgb(191, 10, 48);
    color: #fff;
  }
}

/* User Profile Page styling */
.userProfileWrapper {
  /* Profile User Avatar*/
  &.t-wrap {
    margin: 15px 0 0;
    height: calc(100% - 15px);
    background: #fff;
  }
  .avatar-holder {
    margin: 0 0 50px;
    .avatar {
      position: relative;
      display: block;
      width: 150px;
      height: 150px;
      margin: 0 auto 15px;
      border-radius: 100%;
      .edit-icon {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: -5px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 100%;
        background: $light-gray;
        padding: 9px 12px;
        text-align: center;
      }
      &:hover {
        .edit-icon {
          cursor: pointer;
          background: rgb(45, 158, 45);
          color: #fff;
        }
      }
    }
    input[type="file"] {
      display: none;
    }

    .form-label {
      padding-top: 7px;
    }
  }

  /* Profile Edit bar */
  .edit-bar {
    position: relative;
    border-bottom: 1px solid $border-color;
    margin: 0 0 20px;
    padding: 0 50px 0 0;
    .user-info {
      text-transform: capitalize;
      margin: 0 0 3px;
      p {
        display: inline-block;
        margin-bottom: 0;
        .name {
          display: inline-block;
          margin: 0 0 0 5px;
          font-size: 20px;
          font-weight: bold;
        }
        .role {
          display: inline-block;
          color: gray;
          margin-left: 5px;
        }
      }
      i {
        font-size: 20px;
      }
    }
    .edit-icon {
      display: none;
      //position: absolute;
      //z-index: 2;
      //top:3px;
      //right: 0;
      //width: 35px;
      //height: 35px;
      //overflow: hidden;
      //border-radius: 100%;
      //background: $light-gray;
      //padding: 9px 12px;
      //text-align: center;
      //&:hover{
      //  cursor: pointer;
      //  background: $brand-color-primary;
      //  color: #fff;
      //}
    }
  }

  /* User Profile Form */
  .form-wrap {
    padding: 20px 0;
  }
  .main-holder {
    max-width: 700px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
  }
  .form-group {
    .title {
      margin: 10px 0 0;
      display: block;
      text-align: right;
    }
    .alert {
      padding: 10px 0;
      margin-bottom: 0;
    }
    .alert-danger {
      background: none;
      border: none;
    }
  }

  .btn-group {
    .btn {
      margin: 0;
    }
  }
}
