// Animation

.animated-appear {
  animation: 0.6s fadeInRight;
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes flash {
  0% {
    box-shadow: 0px 0px 6px 3px var(--secondary-color);
  }

  100% {
    box-shadow: 0px 0px 6px 4px var(--secondary-color);
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@keyframes freefallArrow {
  to {
    bottom: 1px;
  }
}

@keyframes freefallStem {
  to {
    height: 0;
  }
}

@keyframes freefallEdge {
  0% {
    bottom: 1px;
  }
  30% {
    transform: rotate(0deg);
    bottom: 1px;
  }
  100% {
    width: 100%;
    transform: rotate(0deg);
    bottom: 1px;
  }
}

@keyframes fillColor {
  to {
    height: 100%;
  }
}

@keyframes largeBtn {
  to {
    width: 130px;
  }
}
