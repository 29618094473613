.scale {
  padding: 10px;

  .scale-container {
    text-align: center;
    width: 100%;
  }

  @at-root .scale-value {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 25px;
    border-right: 1px solid grey;
    border-top: 1px solid grey;
    position: relative;

    @include with-count(4) {
      width: 25%;
    }

    @include with-count(12) {
      width: 8%;
    }

    @media only screen and (max-width: $small-screen) {
      width: 25px;
    }

    &:first-child {
      border-left: 1px solid grey;
    }

    .text {
      position: absolute;
      bottom: -5px;
      right: -4px;
      background: white;
    }
  }

  .base {
    padding-top: 10px;
    width: 100%;

    .left-value,
    .right-value {
      color: black;
      display: inline-block;
      font-size: 12px;
    }

    .left-value {
      width: 105px;
    }

    .right-value {
      float: right;
      width: 120px;
    }
  }
}

.highlight {
  color: #32b0ae !important;
  font-weight: bold;
  cursor: pointer;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: transparent;
}

.download-button-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  background: var(--primary-color);
  margin: 0;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  z-index: 10;

  &.downloaded {
    animation: largeBtn 0.2s ease-in 1.3s forwards;
    // .text {
    //   transition: all 300ms 2s;
    //   display: block;
    // }

    .button-filler {
      animation: fillColor 0.3s ease-in 1s forwards;
    }

    .download {
      // transition: all 0s 2s;
      // display: none;

      .arrow-wrapper {
        animation: freefallArrow 0.3s ease-in forwards;
      }

      .arrow-stem {
        animation: freefallStem 0.3s ease-in 0.3s forwards;
      }

      .arrow-left,
      .arrow-right {
        animation: freefallEdge 0.3s ease-in 0.6s forwards;
      }
    }
  }

  .button-filler {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 0px;
    background-color: $green;
    z-index: -1;
  }

  // &.downloaded {
  //   width: 100px;
  //   background: $blue-green;
  // }

  .text {
    display: none;
    color: white;
    text-align: center;
    position: relative;
    top: 3px;
  }

  .download {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    color: $white;
    margin: auto;

    .arrow-wrapper {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 11.5px;
      display: block;
      width: 18px;
      height: 27px;
      margin-right: auto;
      margin-left: auto;

      .arrow-left {
        position: absolute;
        left: 2px;
        bottom: 2px;
        width: 8px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;
        transform: rotate(45deg);
      }
      .arrow-stem {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: block;
        width: 2px;
        height: 20px;
        margin-right: auto;
        margin-left: auto;
        border-radius: 1px;
        background-color: #fff;
      }
      .arrow-right {
        position: absolute;
        right: 2px;
        bottom: 2px;
        width: 8px;
        height: 2px;
        background-color: #fff;
        transform: rotate(-45deg);
      }
    }
  }
}

.slider-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .backdrop {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: unset;
  }

  .media {
    width: 50vw;
    height: 70vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    display: block;

    .full-size {
      height: calc(100% - 100px);

      audio,
      video,
      img {
        width: 100%;
        height: 100%;
      }

      .media-container {
        display: block;
        height: 100%;
        width: 100%;
      }

      .media-container img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .thumbnail-list {
      width: 100%;
      overflow-y: hidden;
      overflow-x: scroll;
      height: 100px;
      padding: 0;
      margin-top: 5px;
      list-style-type: none;

      li {
        width: 95px;
        height: 95px;
        margin-right: 5px;
        display: inline-block;
        border: 2px solid transparent;

        &.active {
          border: 2px solid royalblue;
        }

        &:hover,
        &:focus {
          border: 2px solid whitesmoke;
        }

        span {
          display: block;
          width: 100%;
          height: 100%;
        }

        audio,
        video,
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.media-container {
  .thumb {
    display: inline-block;
  }

  &.small {
    video,
    img {
      max-width: 100%;
    }
    audio {
      width: 100%;
    }
  }

  audio,
  video,
  img {
    max-width: 100%;
    height: 140px;
    margin-right: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
  }

  audio {
    width: calc(50% - 5px);
    height: 50px;
  }

  canvas {
    // display: none;
  }

  .video-container {
    position: relative;
    width: 100%;

    // video {
    //     // position: fixed;
    //     // top: -400px;
    //     // left: -400px;
    // }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.user-account {
  display: inline-block;
  vertical-align: middle;

  > * {
    padding: 5px 0 5px 0;
    display: table-cell;
    vertical-align: middle;
  }
  span {
    &.user-img {
      span {
        display: block;
        width: 50px;
        height: 50px;
        overflow: hidden;
        line-height: 45px;
        text-align: center;
        color: var(--primary-color);
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.7;
        background: var(--secondary-color);
        border-radius: 50%;
        padding: 6% 0;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid var(--secondary-color);
        object-fit: contain;
        object-position: center;
      }
    }

    &.user-name {
      min-width: 70px;
      color: $brand-color-primary;
      font-size: medium;
      font-weight: bold;
      span {
        display: block;
        font-weight: normal;
        font-size: small;
        text-align: left;
      }
    }
  }
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.bold {
  font-weight: bold;
}

.comment {
  min-height: 20px;
  padding: 9px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  h4 {
    font-size: 18px;
  }
}

.panel {
  .panel-body {
    padding: 15px;
  }
}

// html {
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently
//                                 supported by Chrome and Opera */
// }

button,
a,
[class*="dropdown"] {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
.rcs-custom-scroll .rcs-inner-handle {
  background-color: grey;
}
