.invoices-modal {
  .error {
    text-align: center;
    color: $color7;
  }

  .invoice-loadmore {
    padding: 10px 0;
  }

  .invoice-header {
    display: inline-block;
    width: 95%;
  }

  @media #{$MaxPhone} {
    .modal-body {
      font-size: 12px;
    }

    .content-wrapper {
      height: calc(100% + 10px) !important;
    }
  }

  .invoice-table {
    text-align: center;

    th {
      background: white;
      position: sticky;
      top: 0;
      text-align: center;
    }

    & tbody tr:hover {
      cursor: pointer;
      background: $light-gray;
      color: $filter-color;
    }
  }

  .back-button {
    font-size: 18px;
    cursor: pointer;
  }

  .not-found {
    text-align: center;
  }

  .recepit-header {
    border-top: 2px solid #ddd;
  }

  .invoice-details {
    &:not(:first-child) {
      padding-top: 30px;
      border-top: 1px dotted black;
    }

    .table-heads {
      width: 76%;
      display: inline-block;
      padding-left: 15px;

      @media #{$MaxPhone} {
        width: 60%;
      }
    }

    .table-body {
      display: inline-block;
      width: 24%;
      font-weight: normal;

      @media #{$MaxPhone} {
        width: 40%;
      }
    }

    thead tr:nth-child(3),
    tbody tr,
    tfoot tr {
      padding: 10px 5px;
    }

    .total {
      font-weight: bolder;
      text-align: right;
      padding: 5px 35px;
    }

    .receipt-footer {
      border-bottom: 2px solid #ddd;
      background: $light-gray-2;
      color: black;
    }

    .row-no {
      border-right: 2px solid #ddd;
      text-align: center;
    }

    .item-name {
      width: 55%;
      text-align: left;
    }

    .code-name {
      width: 15%;
      text-align: center;
    }

    .price,
    .total-item-price {
      width: 95px;
      text-align: center;
    }

    .quantity {
      width: 70px;
      text-align: center;
    }
  }

  .modal-footer .search-form {
    width: 40%;
  }

  .table-row {
    border-bottom: 2px solid #ddd;
    text-align: center;
  }

  @media #{$MaxPhone} {
    .modal-dialog {
      width: 100%;
      margin: 0;
    }

    .custom-scroll .content-wrapper {
      overflow-x: scroll;
    }
  }
}

.invoice-icon {
  color: $color7;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  &:hover {
    color: $filter-color;
  }
}

.lazy-load-container {
  display: inline-block;
  width: 60px;
  position: relative;
}

.feedback-dropdown {
  float: right;
  color: grey;

  .up {
    position: absolute;
    top: -5px;
    left: 18px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 0 4px 2px grey;
    background: white;

    &:after {
      content: " ";
      background: white;
      width: 20px;
      height: 10px;
      position: absolute;
      top: 4px;
      left: -1px;
      transform: rotate(-45deg);
    }
  }

  .bottom {
    position: absolute;
    top: auto;
    bottom: -5px;
    left: 18px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 0 4px 2px grey;
    background: white;

    &:after {
      content: " ";
      background: white;
      width: 20px;
      height: 10px;
      position: absolute;
      top: -4px;
      left: -8px;
      transform: rotate(-45deg);
    }
  }

  .left {
    position: absolute;
    left: -5px;
    top: 18px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 0 4px 2px grey;
    background: white;

    &:after {
      content: " ";
      background: white;
      width: 10px;
      height: 20px;
      position: absolute;
      top: -10px;
      left: 3px;
      transform: rotate(-45deg);
    }
  }

  .right {
    position: absolute;
    right: -5px;
    top: 18px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    box-shadow: 0 0 4px 2px grey;
    background: white;

    &:after {
      content: " ";
      background: white;
      width: 10px;
      height: 20px;
      position: absolute;
      top: -2px;
      left: -5px;
      transform: rotate(-45deg);
    }
  }

  .menu {
    box-shadow: 0px 0px 6px 2px grey;
    background: white;
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: -18px;
    z-index: 1;
    padding: 10px 0;
    border-radius: 15px;
    width: 200px;

    &.opens-upside {
      bottom: 30px;
      top: auto;
    }

    &.small {
      width: 150px;
    }

    &.scale-menu {
      width: 400px;

      &.small {
        width: 250px;
      }
    }

    &.sub {
      left: calc(100% - 10px);
      top: 0;
      width: 100%;

      &.opensleft {
        left: calc(-100% + 10px);
      }

      &.opensright {
        right: calc(-100% + 10px);
      }
    }

    &.active {
      display: block;
    }

    .options {
      padding-top: 5px;
      list-style-type: none;
      padding: 0px;

      .icon {
        float: right;
        padding: 3px 0;
      }

      .option {
        border-bottom: 1px solid #cdcdcc;
        padding: 5px 12px;
        white-space: normal;
        text-align: center;
        color: #cdcdcc;
        position: relative;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.chat-box {
  font-size: 14px;
  margin: 0px 20px 20px 20px;

  .chat-list-container {
    padding: 0 20px;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid var(--primary-color);
    border-radius: 3px;

    .message {
      background: #5894c8;
      padding: 10px;
      color: white;
      margin: 10px 0;
      position: relative;
      display: inline-block;
      width: 80%;
      float: right;
      &:after {
        background: url(../img/arrow-right.jpg) no-repeat;
        width: 12px;
        height: 20px;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }

      &.user {
        background: #f5f5f5;
        color: #666;
        float: left;
        &:after {
          background: url(../img/arrow-left.jpg) no-repeat;
          width: 10px;
          height: 19px;
          right: 100%;
          left: auto;
        }
      }

      .timestamp {
        float: right;
        font-size: 12px;
        margin: 3px 0 0;

        .fa {
          margin-left: 5px;
        }
      }
    }
  }
  .text-area-contianer {
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    margin: -5px 0;
    position: relative;

    textarea {
      height: 100%;
      width: 100%;
      border: 0;
      resize: none;

      &:hover,
      &:active,
      &:focus {
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
      }
    }

    .button-container {
      text-align: right;
      margin: 0px 0px 10px 0px;

      .fa {
        margin-right: 10px;
      }

      .btn-info:last-of-type {
        margin-left: 10px;
        margin-right: 10px;
      }
      button:first-of-type,
      button:last-of-type {
        border-color: var(--primary-color);
        border-radius: 0px;

        &:active,
        &:focus,
        &:visited {
          border-color: var(--primary-color);
        }

        .caret {
          border: 4px solid;
          border-color: transparent transparent white transparent;
          border-bottom: dashed;
          margin: -4px 0 0;
        }
      }
      button:first-of-type {
        background: white;
        color: var(--primary-color);

        &:active,
        &:focus,
        &:visited {
          background: white;
        }
      }

      button:last-of-type {
        background: var(--primary-color);
        color: white;

        &:active,
        &:focus,
        &:visited {
          background: var(--primary-color);
        }
      }

      .dropdown-menu {
        left: unset !important;
        right: calc(100% - 29px) !important;
        border: 0;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
        border-radius: 3px 3px 0 0;
        padding: 0;
        li {
          border-top: 1px solid #a7a7a7;
          &:first-child {
            border: 0;
          }
          a {
            padding: 8px 16px;
            &:hover {
              color: #2d4e71;
            }
          }
        }
      }

      .dropdown-item {
        color: var(--primary-color);
      }
    }
  }
}

.dropup {
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    min-width: 137px !important;
  }
  .dropdown-menu {
    bottom: 100% !important;
    top: unset !important;
    border: 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    border-radius: 3px 3px 0 0;
    padding: 0;
    li {
      border-top: 1px solid #a7a7a7;
      &:first-child {
        border: 0;
      }
      a {
        padding: 8px 16px;
        &:hover {
          color: #2d4e71;
        }
      }
    }
  }
}

.notification-button {
  margin-left: 5px;
  vertical-align: middle;
  box-shadow: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  position: relative;
  color: white;
  margin-right: 20px;

  i {
    font-size: 16px;
  }

  &.wrapper__button {
    color: $orange;
  }

  img {
    position: relative;
    top: -2px;
  }
}

// Notifications Styles
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  &__button {
    color: $orange;
    position: relative;
    .counter {
      height: 15px;
      width: 15px;
      background-color: $red;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      position: absolute;
      top: -4px;
      right: 4px;
      border-radius: 50%;
    }
  }
}

.notifications {
  position: absolute;
  box-shadow: 0 1px 10px 1px rgba(1, 1, 1, 0.1);
  width: 400px;
  background-color: $white;
  z-index: 99;
  cursor: initial;

  &.menu:before {
    content: " ";
    width: 0;
    height: 0;
    border-bottom: 15px solid $white;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    position: absolute;
    left: calc(50% + 75px);
    top: -30px;
  }

  a {
    text-decoration: none;
    color: $black;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      text-decoration: none;
      color: $black;
    }
  }

  &--card {
    position: unset;
    top: unset;
    right: unset;
    margin-right: 10px;
    padding: 0px;
    flex: 0 0 23%;
    float: left;
    height: calc(100vh - 92px);

    @media (min-width: 992px) {
      // set col md 4 width for this element as this is over riden by notification menu
      width: calc(25% - 10px);
    }

    .notifications--list {
      height: calc(100vh - 100px);
    }
  }

  &--heading {
    background-color: $white;
    border-bottom-color: $grey;
    color: #333;
    box-shadow: 0 3px 3px 1px $darkgrey;
    padding: 10px 0;
    position: relative;
    h5 {
      font-size: 15px;
      text-align: center;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0;
    }
    .up {
      height: 30px;
      width: 30px;
      margin: -20px auto 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  &--list {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0;
    color: #707070;

    .holder.new {
      background-color: $lightgrey;

      .green-dot {
        content: "";
        height: 10px;
        width: 10px;
        background-color: $green;
        // position: absolute;
        // right: 8px;
        // top: 40%;
        border-radius: 50%;
        vertical-align: middle;
      }
    }
    .holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom-style: solid;
      border-bottom-color: $darkgrey;
      border-bottom-width: 1px;
      padding: 12px 8px;

      &:hover {
        background-color: $darkgrey;
      }

      &.active {
        background-color: #bbb;
      }

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
      .info {
        width: 82%;
        margin-left: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .type {
          font-size: 10.7px;
          width: 100%;
          text-align: left;
          margin: 0;
        }
        .date {
          font-size: 9px;
          color: $grey;
          margin-top: 3px;
          text-align: left;
          margin: 0;
        }
      }
      .tag {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background-color: $green;
        margin-left: 10px;
      }
    }
  }
  .notifications--button {
    align-content: end;
    background-color: $white;
    box-shadow: 0 3px 3px 1px $darkgrey;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;

    .see-all {
      border-radius: 3px;
      font-size: 13px;
      font-weight: 500;
      color: $white;
      background-color: var(--primary-color);
      padding: 5px 10px;
    }
  }
}
