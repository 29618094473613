.question table {
  height: 310px;
  display: -moz-groupbox;
  empty-cells: show;

  & thead {
    display: inline-table;
    width: 100%;
  }

  & tbody {
    display: block;
    width: 100%;
    overflow-y: scroll;
    height: 270px;
  }

  & tr {
    display: table;
    width: 100%;
  }

  & tr td:first-child,
  & tr th:first-child {
    width: 75%;
  }

  & tr td,
  & tr td {
    width: 25%;
  }
}
