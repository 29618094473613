a {
  text-decoration: none;
  color: var(--primary-color);
  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

// adding bootstrap style fixes
.btn,
.input,
.html {
  font-size: 14px;
  line-height: 1.42857143;
}

.btn-group-vertical > .btn-group::after,
.btn-group-vertical > .btn-group::before,
.btn-toolbar::after,
.btn-toolbar::before,
.clearfix::after,
.clearfix::before,
.container-fluid::after,
.container-fluid::before,
.container::after,
.container::before,
.dl-horizontal dd::after,
.dl-horizontal dd::before,
.form-horizontal .form-group::after,
.form-horizontal .form-group::before,
.modal-footer::after,
.modal-footer::before,
.modal-header::after,
.modal-header::before,
.nav::after,
.nav::before,
.navbar-collapse::after,
.navbar-collapse::before,
.navbar-header::after,
.navbar-header::before,
.navbar::after,
.navbar::before,
.pager::after,
.pager::before,
.panel-body::after,
.panel-body::before {
  display: table;
  content: " ";
}

.btn-group-vertical > .btn-group::after,
.btn-toolbar::after,
.clearfix::after,
.container-fluid::after,
.container::after,
.dl-horizontal dd::after,
.form-horizontal .form-group::after,
.modal-footer::after,
.modal-header::after,
.nav::after,
.navbar-collapse::after,
.navbar-header::after,
.navbar::after,
.pager::after,
.panel-body::after,
.row::after {
  clear: both;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.panel {
  margin-bottom: 20px;

  .panel-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &.info {
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
    }
  }
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

.form-control {
  font-size: 14px;
}
