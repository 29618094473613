.panel {
  border: 0;
  border-radius: 0;
  // overflow: hidden;
  background: white;

  .panel-header {
    font-weight: bold;
  }

  .panel-body {
    border-left: 6px solid transparent;

    .media {
      margin-top: 0;
      width: 100%;
      display: inline-block;
      overflow: visible;

      .counter {
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: top;
        border-radius: 50%;
        background-color: $green;
        margin-right: 5px;
        text-align: center;
        line-height: 50px;
        font-size: 30px;
        color: white;

        &.red {
          background-color: $color1;
        }

        &.orange {
          background-color: $color2;
        }

        &.green {
          background-color: $color3;
        }
      }

      .data {
        display: inline-block;
        padding-top: 5px;
        width: calc(100% - 55px);

        > div {
          margin-left: 5px;
          font-size: 15px;
          margin-bottom: 3px;

          .dropdown {
            button {
              border: 0;
              box-shadow: none;
              background-color: transparent;

              &:hover {
                background-color: transparent;
              }

              &:active,
              &:focus {
                box-shadow: none;
                background-color: transparent;
              }

              .dropdown-toggle {
                background-color: transparent;
                box-shadow: none;
              }
            }

            ul {
              padding: 0;

              li {
                float: none;
                margin: 0;

                a {
                  &:focus {
                    outline: 0;
                  }
                }
              }
            }
          }
        }

        p {
          font-size: 14px;

          &:first-child {
            font-size: 16px;
            padding-left: 5px;
            margin-bottom: 5px;
          }
        }

        ul {
          padding: 0 0 0 5px;
          list-style: none;

          li {
            float: left;
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }

            label {
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 0;
              word-wrap: break-word;
              vertical-align: top;
            }
          }
        }

        .fa {
          font-size: 14px;
          margin-right: 5px;

          &.green {
            color: $color3;
          }

          &.blue {
            color: $blue-green;
          }
        }
        .badge {
          float: right;
          background-color: $color4;
        }
      }
    }

    .time {
      display: inline-block;
      float: right;
      font-size: 12px;
    }

    .bottom-list {
      margin: 20px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        font-size: 13px;
        padding: 3px 6px;
        margin-bottom: 2px;
        margin-right: 5px;

        .fa {
          margin-right: 3px;
          color: darken($light-gray-1, 50%);
        }
      }
    }
  }
}

// Feedback
.feedback {
  .panel {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// feedback List
.feedback-list {
  .list-stats-card {
    color: (--primary-color);
    padding: 10px 0;
    text-align: center;
    background: white;
    border-bottom: 1px solid #eee;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .content-wrapper {
    height: auto !important;
  }

  .panel {
    margin-bottom: 15px;
    cursor: pointer;
    border-left: 5px solid transparent;

    &.selected {
      border-left: 5px solid darken($light-gray-1, 1%);
      box-shadow: 0 2px 4px lighten($light-gray-2, 10%);
      position: relative;
    }

    &.unprocessed:not(.selected) {
      border-left: 5px solid var(--primary-color);
    }

    .badge-primary {
      display: inline-block;
      float: right;
      background: var(--primary-color);
      color: white;
      padding: 2px 5px;
      font-size: 12px;
    }
  }

  .custom-scroll {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;

    @media #{$min-tablet} {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }
  }
}

// Feedback QuestionaireDetail
.details-modal {
  .list-group {
    margin-bottom: 0;

    h5 {
      margin-top: 0;
      font-weight: 500;
    }

    > .list-group-item {
      border: 0;
      padding: 5px 10px;

      > .list-group {
        margin-bottom: 10px;

        .list-group-item {
          display: block;
          border: 0;
          position: relative;
          border-radius: 0;
          padding-left: 20px;
          vertical-align: top;

          &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: $blue-green;
            border: 1px solid $blue-green;
            left: 0;
            top: 8px;
          }

          &.active {
            background-color: transparent;
            color: $body-text;

            &:before {
              background-color: $blue-green;
            }
          }
        }
      }
    }

    .suboptions {
      li {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}

.details {
  overflow: auto;
  overflow-x: hidden;
  padding-left: 0;

  &.public-view {
    padding-left: 15px;
  }

  .smooth {
    scroll-behavior: smooth;
  }

  @media #{$min-tablet} {
    display: block;
  }

  @media #{$MaxPhone} {
    padding-right: 0;
  }

  &.scollbar {
    overflow: scroll hidden;
    max-height: 85vh;
    white-space: nowrap;

    &:before {
      box-shadow: inset -11px 0px 24px -9px rgba(0, 0, 0, 0.75);
    }
  }

  &.full-height {
    max-height: 100vh;
  }

  .card {
    box-shadow: 0px 0px 2px 2px #ddd;
    display: inline-block;
    width: 50%;
    vertical-align: middle;
    &:not(:first-child) {
      margin-left: 5px;
    }

    @media #{$tablet} {
      width: 100%;
    }

    &.full-height {
      height: 100vh;
    }
  }

  .panel {
    margin-bottom: 0;
    // margin-top: 15px;
    min-height: calc(100vh - 100px);
    white-space: normal;

    .content-wrapper {
      padding-top: 0;
    }

    .panel-body {
      border-top: 1px solid $light-gray-1;
      @media screen and (max-width: $small-screen) {
        padding: 15px 5px;
      }
      &:first-child {
        border: 0;
      }

      span {
        font-weight: 400;
        margin-bottom: 10px;

        &.error.badge-danger {
          float: right;
          padding: 10px 20px;
          margin: 0;
          background: $color1;
          color: white;
          box-shadow: -1px 3px 8px 0px grey;
          font-size: 75%;
          font-weight: 700;
          border-radius: 2px;
        }

        &.control-label {
          font-weight: 600;
        }
      }

      .form-group {
        margin-bottom: 20px;
      }

      hr {
        width: calc(100% + 30px);
        margin-left: -15px;
      }

      .bottom-list {
        margin: 16px 0 0 0;
      }
    }
  }

  .list-group {
    margin-bottom: 0;

    h5 {
      margin-top: 0;
      font-weight: 500;
      font-size: 14px;
      display: inline-block;
    }

    > .list-group-item {
      border: 0;
      padding: 5px 10px;
      width: 100%;

      > .list-group {
        margin-bottom: 10px;

        .list-group-item {
          display: inline-block;
          border: 0;
          width: 100%;
          position: relative;
          border-radius: 0;
          padding-left: 20px;
          vertical-align: top;

          &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: $blue-green;
            border: 1px solid $blue-green;
            left: 0;
            top: 8px;
          }

          &.active {
            background-color: transparent;
            color: $body-text;

            &:before {
              background-color: $blue-green;
            }
          }
        }
      }
    }

    .suboptions {
      li {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}

.no-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: bold;
    color: darken($light-gray, 15%);
    text-shadow: 0 1px 0 white;
    .fa {
      color: $brand-color-secondary;
      margin-right: 15px;
    }
  }
}
.feedbacks {
  margin-top: 20px;

  .select-holder {
    background: #fff;
    padding: 25px 25px 10px 25px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    max-width: 485px;
    margin: 0 auto 20px;

    .select-block {
      max-width: 300px;
      margin: 0 auto;

      .field {
        margin: 0 0 15px;

        .label {
          font-weight: normal;
          color: lighten(black, 20%);
          font-size: 14px;
          display: inline-block;
          width: 110px;

          @media screen and (max-width: $small-screen) {
            width: 90px;
          }
        }

        .select {
          display: inline-block;
          vertical-align: middle;
          min-width: 168px;
          height: 43px;
          border: 2px solid #efefef;
          color: #1a1a1a;
          background: #fff;
          outline: none;
          position: relative;
          text-align: left;

          & button {
            background: inherit;
            color: inherit;
            border: 0;
            padding: 0 25px;
            width: 100%;
            text-align: left;
            line-height: 2.7;

            &:after {
              position: absolute;
              top: 45%;
              right: 10px;
            }
          }

          @media screen and (max-width: $small-screen) {
            width: 140;
          }
        }
      }
    }

    b {
      font-size: 13px;
      font-weight: 500;
      color: darken($light-gray-2, 30%);
      float: left;
    }
    .spam-toggle {
      display: inline-flex;
      // border: 1px solid #eaeaea;
      padding: 4px 10px;
      .spam-title {
        margin-right: 3px;
        font-size: 12px;
        line-height: 1.5;
      }
    }
    .dropdown {
      margin-left: 10px;

      button {
        border-color: $light-gray-1;
        border-radius: 0;
        padding: 4px 10px;
        &:focus {
          border-color: $light-gray-1;
          box-shadow: none;
          background-color: $light-gray-1;
        }
      }
      .dropdown-menu {
        min-width: 168px;
        padding: 0 25px;
        font-size: 12px;
        margin-top: 0;
        right: 0;
        left: auto;
        border-radius: 0;
        border: 1px solid $light-gray-1;
        box-shadow: 0 2px 5px $light-gray-1;
        padding: 0;
        li {
          border-bottom: 1px solid $light-gray;
        }
      }
    }
  }
}

.feedback-detail-transaction-box {
  margin: 15px 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $small-screen) {
    font-size: 12px;
  }

  @media #{$MaxPhone} {
    flex-flow: column;

    & div {
      margin-top: 10px;
      padding-left: 15px;
    }
  }
}
