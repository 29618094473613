#forgot-password {
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background-color: #c4c4c4;
  background: url("../img/background.png") no-repeat center;

  .forgot-password-holder {
    position: absolute;
    max-width: 450px;
    padding: 30px;
    box-sizing: border-box;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    .logo {
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 90%;
      }
    }
  }
}
