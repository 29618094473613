// reset selection border for mac chrome and other devices to normalize css across browsers
a:focus,
a:active,
a:hover,
a:visited,
button:focus,
button:active,
button:hover,
button:visited,
input:focus,
input:active,
input:hover,
input:visited,
textarea:focus,
textarea:active,
textarea:hover,
textarea:visited,
video:focus,
video:active,
video:hover,
video:visited,
audio:focus,
audio:active,
audio:hover,
audio:visited,
img:focus,
img:active,
img:hover,
img:visited {
  outline: none;
}

.input-1 {
  background-color: white;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  padding: 10px 0 15px 56px;
  color: black;
  height: auto;
  font-size: 16px;
  position: relative;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
  .fa {
    font-size: 16px;
    position: absolute;
    top: 13px;
    left: 15px;
    z-index: 1;
  }

  .label {
    .grey-text {
      font-size: 13px;
      color: grey;
    }
  }
}

.button-1 {
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: darken($brand-color-primary, 12%);
  }
}

.search-form {
  display: inline-flex;
  width: 100%;
  position: relative;

  & input {
    height: 42px;
    width: 90%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
  }

  & button {
    background: $filter-color;
    color: white;
    cursor: pointer;
    border: 1px solid $filter-color;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;

    &:active,
    &:focus,
    &:hover,
    &[disabled],
    &[disabled]:hover {
      background: darken($filter-color, 2%);
      color: white;
      border: 1px solid $filter-color;
    }
  }

  & .cross-icon {
    position: absolute;
    right: 50px;
    top: 14px;
    color: lighten($color: black, $amount: 75);
    cursor: pointer;
  }

  & .cross-icon:hover {
    color: lighten($color: black, $amount: 50);
  }
}

.primary-btn {
  padding: 10px 20px;
  background: rgb(0, 90, 171);
  border-radius: 5px;
  color: #fff;
  text-decoration: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #fff;
    background: darken(rgb(0, 90, 171), 12%);
    text-decoration: none;
  }
}

.custom-dropdown {
  color: #555;
  line-height: 1.42857143;

  .header {
    display: inline-flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 5px 6px 12px;
    min-height: 34px;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;

    &:after {
      content: "\25BE";
      font-size: 12px;
    }
  }

  .custom-dropdown-menu {
    padding: 5px 5px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 5;
    width: 100%;
    background: white;
    display: none;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .custom-dropdown-items {
    list-style: none;
    margin: 0;
    padding: 5px 0;

    & li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }

    & li:hover,
    .selected {
      background: #3297fd;
      color: white;
    }
  }

  .sub-items {
    padding-left: 10px;
  }

  &.disabled {
    cursor: not-allowed;
    background: darken(white, 50);
  }

  &.open {
    .overlay {
      display: block;
      background: transparent;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }

    .custom-dropdown-menu,
    .header {
      border: 1px solid #66afe9;
      outline: none;
    }
    .header {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
    .custom-dropdown-menu {
      display: block;
      box-shadow: 5px 5px 20px 1px #ddd;
    }
  }
}

.load-more {
  &.vertical {
    transform: rotate(-90deg);
    display: inline-block;
    vertical-align: middle;
  }
}
