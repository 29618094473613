.new-user-heading {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  color: #15305b;
  font-weight: bold;
}

.new-user-form-body {
  margin-left: -10%;

  .row {
    margin-bottom: 10px;
  }
  .form-control {
    background: #efefef;
    margin-bottom: 10px;
  }

  .save-btn {
    margin-top: 30px;
    padding: 5px 50px;
    border-radius: 20px;
    background: #15305b;
    color: white;
    border: none;
    margin-left: 30%;
  }
}

.new-user-container {
  margin-top: 55px;
}

.department-select-popup {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.department-selection-title {
  text-align: center;
  margin-top: 17px;
  color: #15305b;
  font-family: inherit;
}

.department-selection-close {
  border-radius: 25px;
  display: block;
  width: 95px;
  margin: 10px auto 25px auto;
  background: #15305b;
  color: yellow;
  font-size: medium;
}

.department-name {
  border-radius: 25px;
  border: 2px solid #15305b;
  padding: 5px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.department-names {
  margin-left: 10%;
  font-size: unset;
  text-align: center;
  color: #15305b;
}

.department-name-select {
  background: #15305b;
  color: white;
  cursor: pointer;
}
