.feedback-groups-list-table {
  td:last-child {
    button {
      width: 100px;
    }
  }
}

.users-list-table {
  @extend .feedback-groups-list-table;
}

.levels-list-table {
  td:last-child {
    width: 30%;
    button {
      width: 80px;
    }
  }
}

.table-section {
  padding: 70px 100px 20px;
}

.add-btn {
  margin: 20px 5px 20px 5px;
  float: right;
  position: relative;
  right: 95px;
}

.feedback-group-form,
.level-form,
.user-form {
  background-color: #ffffff;
  margin: 30px auto;
  padding: 50px;
  width: 60%;
  border-radius: 5px;
  .form-group {
    margin-top: 15px;
    padding: 5px;
  }

  .panel {
    border-radius: 5px;
    border: solid 1px #ccc;
  }
}
.feedback-group-form-btn,
.level-form-btn,
.add-user-btn {
  float: right;
}

.add-analyzer-btn {
  float: right;
}

.remove-analyzer-btn {
  margin-top: 45px;
  position: absolute;
  right: 0;

  &.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

.questionnaire-division-container {
  margin-top: 50px;

  .questionnaire-stats {
    margin-top: 40px;
  }

  .division-details {
    .Collapsible {
      width: 100%;
    }

    .questionnaire-edit {
      margin-top: 10px;
    }

    .percentage-input {
      input {
        width: 100px;
        padding: 5px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-align: center;
      }

      input[type="number"]:disabled {
        background: lightgrey;
        cursor: not-allowed;
      }
    }
  }

  .roles-container {
    margin-left: 10px;

    &:last-child {
      padding-bottom: 20px;
    }

    .questionnaire-table {
      margin-left: 30px;

      th {
        padding-bottom: 10px;
      }

      tbody > tr > td {
        border-top: 1px solid #ddd !important;
      }

      th:last-child {
        text-align: center;
      }

      tr:last-child {
        font-weight: bold;
      }

      td {
        padding: 0;
        margin: auto;

        &:last-child {
          text-align: center;
        }
      }
    }

    .leaf-node {
      height: 50px;
    }

    .questionnaire-update {
      margin: 10px;
      float: right;
    }
  }
}

.color-picker {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;

  > .selected-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }
}

.pickers-container {
  padding: 5px;
}

.picker-container {
  position: relative;

  .picker-popover {
    position: absolute;
    z-index: 999;
  }
}
