.users {
  margin-top: 15px;

  @media #{$phone} {
    margin-top: 30px;
  }

  .row {
    > [class*="col"] {
      padding: 0;
      @media #{$phone} {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .panel {
      border-radius: 0;
      box-shadow: 5px 5px 0 darken($light-gray, 5%);
      border: 1px solid darken($light-gray, 5%);
      margin-bottom: 20px;

      @media #{$phone} {
        margin-bottom: 30px;
      }

      .panel-body {
        padding: 18px 24px;
        border-left: 0;
        position: relative;
        overflow: hidden;

        .media {
          height: 100px;

          .media-left {
            padding: 0 0 20px 0;
            display: block;
            text-align: center;

            @media #{$phone} {
              padding: 0 15px 0 0;
              display: table-cell;
            }

            .user-img {
              width: 100px;
              height: 100px;
              box-sizing: border-box;
              line-height: 100px;
              overflow: hidden;
              border-radius: 50%;
              margin: auto;
              background: url("../img/user-avatar.png") no-repeat center;

              @media #{$phone} {
                margin: 0;
              }

              img {
                width: 100%;
                background-size: 100%;
              }
            }
          }

          .media-body {
            vertical-align: middle;
            display: block;
            width: 100%;
            text-align: center;
            padding-bottom: 10px;

            @media #{$phone} {
              display: table-cell;
              width: 10000px;
              text-align: left;
              padding-bottom: 0;
            }

            .help-block {
              margin-bottom: 0;
              margin-top: 0;
              text-align: center;
              font-size: 12px;
              font-weight: bold;
              color: #737373;

              span {
                font-weight: normal;
              }
            }

            p {
              margin-bottom: 3px;

              .fa {
                margin-right: 5px;
                text-align: center;
                width: 14px;
              }
            }

            .media-heading {
              font-size: 18px;
              color: $brand-color-primary;
              margin-bottom: 15px;

              @media #{$phone} {
                margin-bottom: 5px;
              }

              span {
                font-size: 14px;
                color: darken($light-gray, 40%);
                margin-left: 3px;
                font-weight: normal;
                display: block;

                @media #{$phone} {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}
